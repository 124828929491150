import {connect} from 'react-redux';
import {updateProp} from '../../actions/forms';
import EstablishmentsField from './EstablishmentsField';

/**
 *
 */
const mapStateToProps = () => ({});

/**
 *
 */
function mapDispatchToProps(dispatch) {
	return {
		onSelectionChange(suggestionOrNull) {
			dispatch(updateProp('home', 'establishment', 'uuid', suggestionOrNull ? suggestionOrNull.uuid : null));
			dispatch(updateProp('home', 'establishment', 'placeUuid', suggestionOrNull ? suggestionOrNull.place.uuid : null));
		}
	};
}


/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EstablishmentsField);
