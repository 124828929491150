import {identity} from 'lodash';
import {branch, withState, withHandlers, compose, renderNothing} from 'recompose';
import CookieWarning from './CookieWarning';



/**
 *
 */
const isAccepted = () => !!(window.localStorage && localStorage.getItem('acceptCookies'));

const state = withState('accepted', 'setAcceptance', isAccepted());

const handlers = withHandlers({
	onAccept: (props) => (event) => {
		event.preventDefault();
		if (window.localStorage) {
			localStorage.setItem('acceptCookies', 'yas');
		}
		props.setAcceptance(true);
	}
});

/**
 *
 */
export default compose(
	state,
	handlers,
	branch(
		isAccepted,
		renderNothing,
		identity
	)
)(CookieWarning);
