import React, {Component} from 'react';
import {addLocaleData, IntlProvider} from 'react-intl';
import {Formats} from '../api/locale';



export default class Intl extends Component {
	constructor(props) {
		super(props)

		this.state = {
			locale: undefined,
			messages: undefined
		};
	}

	componentDidMount() {
		this.load(this.props.params.lang);
	}

	componentDidUpdate() {
		if (this.props.params.lang !== this.state.locale) {
			this.load(this.props.params.lang)
		}
	}

	load(locale) {
		this.setState(() => ({
			locale,
			messages: undefined
		}));

		Promise.all([
			import(
				/* webpackChunkName: 'locale-data-[request]' */
				/* webpackInclude: /\/(fr|en|es)\.js$/ */
				`react-intl/locale-data/${locale}`
			),
			import(
				/* webpackChunkName: 'locale-messages-[request]' */
				`../messages/${locale}`
			)
		]).then(([
			{default: data},
			{default: messages}
		]) => {
			addLocaleData(data);
			this.setState(() => ({
				messages
			}));
		});
	}

	render() {
		if (!this.state.messages) {
			return null;
		}

		return (
			<IntlProvider
				locale={this.state.locale}
				messages={this.state.messages}
				formats={Formats}
			>
				{this.props.children}
			</IntlProvider>
		)
	}
}
