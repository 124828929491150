import React, {PropTypes} from 'react';
import {size} from 'lodash';
import FiltersFormContainer from './FiltersFormContainer';



/**
 *
 */
export default function SearchAside(props) {
	return size(props.childPlaces)
		? <FiltersFormContainer {...props} />
		: <noscript />;
}

SearchAside.propTypes = {
	childPlaces: PropTypes.array
};
