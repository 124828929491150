import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';



/**
 *
 */
export default function CookieWarning({onAccept}) {
	return (
		<div className="CookieWarning">
			<p className="CookieWarning-text">
				<FormattedMessage id="CookieWarning.text" />&nbsp;
			</p>
			<button type="button" onClick={onAccept} className="CookieWarning-button">
				<FormattedMessage id="CookieWarning.button" />
			</button>
		</div>
	);
}

CookieWarning.propTypes = {
	onAccept: PropTypes.func.isRequired
};
