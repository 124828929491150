import React, {PropTypes} from 'react';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import {IndexLink} from '../Link';


/**
 *
 */
export default function Summary({place, topic, header}) {
	const summary = place.name ?
		<FormattedHTMLMessage id="page.search.summary.topic_place" values={{place: place.name, topic: topic.name}} /> :
		<FormattedHTMLMessage id="page.search.summary.topic" values={{topic: topic.name}} />;

	return (
		<div className="page-search-summary">
			<p className="page-search-summary-search">
				{summary}
			</p>
			<div className="page-search-summary-backLink">
				<IndexLink to={{pathname: '/'}}>
					<FormattedMessage id="page.search.summary.backLink" />
				</IndexLink>
			</div>
			<div className="page-search-summary-warning">
				{header
					? <p dangerouslySetInnerHTML={{__html: header.text}} />
					: <FormattedHTMLMessage id="page.search.summary.warning" tagName="p" />
				}
			</div>
		</div>
	);
}

Summary.propTypes = {
	place: PropTypes.object,
	topic: PropTypes.object
};
