import {SET} from '../actions/user';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	id: ''
};



/**
 *
 */
const set = (state, {user}) => user === undefined ? {} : user;



/**
 *
 */
export default createReducer(initialState, {
	[SET]: set
});
