import {connect} from 'react-redux';
import {updateProp} from '../../actions/forms';
import EstablishmentsFieldset from './EstablishmentsFieldset';

const mapStateToProps = () => ({});

function mapDispatchToProps(dispatch) {
	return {
		/**
		 * Met à jour le formulaire quand l'étudiant choisi son établissement d'inscription.
		 * On met à jour l'uuid de l'établissment sélectionné afin de pouvoir le retrouver si on ferme, puis re-ouvre la
		 * modale.
		 * On met à jour : `value` et `placeUuid` pour modifier le formulaire sous la modale et retrouver la valeur si on
		 * valide le formulaire.
		 *
		 * @param establishment
		 */
		onChange(establishment) {
			dispatch(updateProp('home', 'establishment', 'value', establishment ? establishment.name : ''));
			dispatch(updateProp('home', 'establishment', 'uuid', establishment ? establishment.uuid : null));
			dispatch(updateProp('home', 'establishment', 'placeUuid', establishment ? establishment.place.uuid : null));
		},
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(EstablishmentsFieldset);
