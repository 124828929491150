import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape, FormattedHTMLMessage} from 'react-intl';
import renderIf from 'render-if';
import {get, isEmpty} from 'lodash';
import FormField from '../FormField';
import MultilineText from '../MultilineText';
import AppointmentFormAttachmentsContainer from './AppointmentFormAttachmentsContainer';
import {TYPE_FACE_TO_FACE, TYPE_PHONE, TYPE_SKYPE} from '../../api/methods';



/**
 *
 */
export default function AppointmentForm(
	{needsSkypeAccount, needsAddress, skypeAccount, selectedType, setSelectedType, onSubmit, onCancel, documents, procedure, subject, user, slot},
	{intl}
) {
	const handleSubmit = (event) => {
		event.preventDefault();
		onSubmit(new FormData(event.target));
	};

	return (
		<form
			className="form form-create-appointment"
			onSubmit={handleSubmit}
		>
			<div className="form-body">
				<div>
					<h2 className="form-field-label form-field-title">
						<FormattedMessage id="appointment.create.form.date.title" />
					</h2>
					<p>
						<FormattedMessage
							id="appointment.create.form.date"
							values={{
								startDay: slot.dateRange.start.format('dddd DD MMMM'),
								startTime: slot.dateRange.start.format('HH:mm'),
								endTime: slot.dateRange.end.format('HH:mm')
							}}
						/>
					</p>
				</div>

				<div>
					<h2 className="form-field-label form-field-title" id="radioFormtype">
						<FormattedMessage id="appointment.create.form.type.title" />
					</h2>

					{renderIf(subject.method.types.length == 1)(() => {
						return (
							<div>
								<p>{intl.formatMessage({id: AppointmentForm.labels[subject.method.types[0].type]})}</p>
								<input 
									type="hidden"
									value={subject.method.types[0].id}
									name="methodType"
								></input>
							</div>
						);
					})}
					
					{renderIf(subject.method.types.length > 1)(() => {
						return (
							<fieldset  aria-labelledby="radioFormtype" className="form-fieldset">
								{subject.method.types.map((object, index) => {
									let label = intl.formatMessage({
									id: AppointmentForm.labels[object.type],
									});
									const uniqueId = object.type + "_" + slot.allocationId + "-" + slot.dateRange.start.format('DD-MM-YY-HH-mm')
										return (
											<label key={object.id} htmlFor={uniqueId}>
												<input
												type="radio"
												value={object.id}
												name="methodType"
												id={uniqueId}
												required
												checked={selectedType == object.type}
												onChange={() => {setSelectedType(object.type)}}
												/>
												{label}
											</label>
										);
								})}
							</fieldset>
						);
					})}
				</div>

				{renderIf(procedure)(() => (
					<div>
						<h2 className="form-field-label form-field-title">
							<FormattedMessage id="appointment.create.form.procedure" />
						</h2>

						<p className="form-procedure"><MultilineText text={procedure} /></p>
					</div>
				))}

				{renderIf(needsAddress)(() => (
					<div>
						<h2 className="form-field-label form-field-title">
							<FormattedMessage id="appointment.create.form.address" />
						</h2>

						<p>
							{subject.address.address}
							<br />
							{subject.address.city}
							<br />
							<a
								target="_blank"
								href={`https://www.google.fr/maps/place/${subject.address.address}+${subject.address.city}`}
								title={intl.formatMessage({id: 'appointment.create.form.address.geolocalize.title'})}
							>
								<FormattedMessage id="appointment.create.form.address.geolocalize" />
							</a>
						</p>
					</div>
				))}


				<FormField
					name="studentComment"
					label={intl.formatMessage({
						id: 'appointment.create.form.label.comment'
					}, {required: subject.comment})}
				>
					<textarea required={subject.comment} rows="2" />
				</FormField>


				{renderIf(needsSkypeAccount && selectedType === TYPE_SKYPE)(() => (
					<FormField
						name="skypeAccount"
						defaultValue={skypeAccount}
						label={intl.formatMessage({
							id: 'appointment.create.form.label.skype'
						})}
					>
						<input type="text" required/>
					</FormField>
				))}

				{renderIf(documents.length)(() => (
					<div>
						<p className="form-infos-documents-title">
							<FormattedMessage id="appointment.create.documents.title" />
						</p>

						<ul className="form-infos-documents-list">
							{documents.map((item, index) =>
								<li key={index}>{item.name}</li>
							)}
						</ul>
					</div>
				))}

				<AppointmentFormAttachmentsContainer />

				{renderIf(!isEmpty(user))(() => (
					<div className="form-contact-details" >
						<h2 className="form-field-label form-field-title">
							<FormattedMessage id="appointment.create.form.contacts" />
						</h2>

						{renderIf(user.mobilePhone)(() => (
							<p style={{marginBottom: 0}}>
								<FormattedMessage
									id="appointment.create.form.contacts.mobilePhone"
									values={{mobilePhone: user.mobilePhone}}
								/>
							</p>
						))}
						{renderIf(user.phone)(() => (
							<p style={{marginBottom: 0}}>
								<FormattedMessage
									id="appointment.create.form.contacts.phone"
									values={{phone: user.phone}}
								/>
							</p>
						))}
						{renderIf(user.email)(() => (
							<p>
								<FormattedMessage
									id="appointment.create.form.contacts.email"
									values={{email: user.email}}
								/>
							</p>
						))}

						<FormattedHTMLMessage id="appointment.create.form.contacts.details"	/>
					</div>
				))}

			</div>

			<div className="form-footer">

				<div className="form-checkbox-contact">
					<input
						className="checkbox-contact"
						type="checkbox"
						id="check_contact_details"
						required="true"
					/>
					<label htmlFor="check_contact_details">
						<FormattedHTMLMessage
							id="appointment.create.form.check.contact_details"
						/>
					</label>
				</div>
				<div className="form-button-footer">
					<button
                        className="button button-large button-primary"
                        type="submit"
					>
						<FormattedMessage id="appointment.create.form.action.submit" />
					</button>
					<button
						className="button button-large button-secondary"
						type="button"
						onClick={onCancel}
					>
						<FormattedMessage id="appointment.create.form.action.cancel" />
					</button>
				</div>

			</div>
		</form>
	);
}

AppointmentForm.contextTypes = {
	intl: intlShape
};

AppointmentForm.propTypes = {
	needsSkypeAccount: PropTypes.bool,
	skypeAccount: PropTypes.string,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired,
	documents: PropTypes.array,
	procedure: PropTypes.string,
	subject: PropTypes.shape({
		address: PropTypes.shape({
			city: PropTypes.string,
			address: PropTypes.string
		}),
		method: PropTypes.shape({
			type: PropTypes.string
		})
	}).isRequired,
	user: PropTypes.shape({
		mobilePhone: PropTypes.string,
		phone: PropTypes.string,
		email: PropTypes.string
	})
};

AppointmentForm.labels = {
	[TYPE_FACE_TO_FACE]: 'method.type.face_to_face',
	[TYPE_PHONE]: 'method.type.phone',
	[TYPE_SKYPE]: 'method.type.skype'
};