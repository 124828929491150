import {forEach, get, size} from 'lodash';
import {DefaultLanguage} from '../api/locale';



/**
 *	Infers the current language from the URL.
 *	This is hacky but gets the job done, avoiding a painful
 *	rewrite of the entire request layer.
 */
function apiUrl(endpoint) {
	const matches = document.location.pathname.replace(window.rdv.basePath, '').match(/^\/(?<lang>[a-z]{2})/i);
	const lang = get(matches, 'groups.lang', DefaultLanguage);
	return `${window.rdv.apiBasePath}/${lang}${endpoint}`;
}

/**
 *
 */
function formatData(data) {
	const form = new FormData();

	forEach(data, (value, key) =>
		form.append(key, value)
	);

	return form;
}

/**
 *
 */
export default function request(endpoint, data = {}) {
	const isFormData = (data instanceof FormData);
	const options = {
		credentials: 'include'
	};

	if (isFormData || size(data)) {
		options.method = 'post';
		options.body = isFormData ? data : formatData(data);
	}

	return fetch(apiUrl(endpoint), options)
		.then((response) =>
			response.json().then((json) => {
				const returnValue = response.ok
					? json
					: Promise.reject({
						response,
						json
					});
				return returnValue;
			})
		);
		// .catch((error) => console.error(error)); // eslint-disable-line no-console
}
