import {compose} from 'recompose';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import {removeMessage} from '../actions/flash';
import renderNothingUnless from './renderNothingUnless';
import Flash from './Flash';



/**
 *
 */
const enhance = compose(
	connect(
		({flash}) => flash,
		(dispatch) => ({
			onRemoveMessage(id) {
				dispatch(removeMessage(id));
			}
		})
	),
	renderNothingUnless(({messages}) =>
		!isEmpty(messages)
	)
);

export default enhance(Flash);
