import {connect} from 'react-redux';
import {compose} from 'recompose';
import {isEmpty} from 'lodash';
import {getAll} from '../../selectors/search';
import renderNothingUnless from '../renderNothingUnless';
import Subject from './Subject';



/**
 *
 */
const enhance = compose(
	connect(getAll),
	renderNothingUnless(({loading, subjects}) =>
		(!loading && !isEmpty(subjects))
	)
);

export default enhance(Subject);
