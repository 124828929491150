import React from 'react';
import {intlShape, FormattedMessage} from 'react-intl';
import Helmet from 'react-helmet';
import {Link, IndexLink} from '../Link';
import {isEmpty} from 'lodash';

/**
 *
 */
export default function SiteMap(props, {intl}) {
	const pageTitle = intl.formatMessage({
		id: 'page.sitemap.title'
	});

	return (
		<article className="Page">
			<Helmet title={pageTitle} />

			<header className="Page-header">
				<h1>
					<FormattedMessage id="page.sitemap.title" />
				</h1>
			</header>

			<div className="Page-body">
				<ul className="page-sitemap-list">
					<li>
						<IndexLink to={{pathname: '/'}}>
							<FormattedMessage id="page.menu.take_appointment" />
						</IndexLink>
					</li>

					{isEmpty(props.route.user)
						? (
							<li>
								<Link
									to={{pathname: '/login-appointments'}}
									title={intl.formatMessage({
										id: 'page.menu.my_appointments.title'
									})}
								>
									<FormattedMessage id="page.menu.my_appointments" />
								</Link>
							</li>
						)
						: (
							<li>
								<Link
									to={{pathname: '/appointments'}}
									title={intl.formatMessage({
										id: 'page.menu.my_appointments.title'
									})}
								>
									<FormattedMessage id="page.menu.my_appointments" />
								</Link>

								<ul className="page-sitemap-list">
									<li>
										<Link to={{pathname: '/appointments/scheduled'}}>
											<FormattedMessage id="page.appointments.scheduled.title" />
										</Link>
									</li>

									<li>
										<Link to={{pathname: '/appointments/attended'}}>
											<FormattedMessage id="page.appointments.attended.title" />
										</Link>
									</li>

									<li>
										<Link to={{pathname: '/appointments/cancelled'}}>
											<FormattedMessage id="page.appointments.cancelled.title" />
										</Link>
									</li>

									<li>
										<Link to={{pathname: '/appointments/missed'}}>
											<FormattedMessage id="page.appointments.missed.title" />
										</Link>
									</li>

									<li>
										<Link to={{pathname: '/appointments/postponed'}}>
											<FormattedMessage id="page.appointments.postponed.title" />
										</Link>
									</li>

									<li>
										<Link to={{pathname: '/appointments/archived'}}>
											<FormattedMessage id="page.appointments.archived.title" />
										</Link>
									</li>
								</ul>
							</li>
						)
					}

					<li>
						<Link to={{pathname: '/help'}}>
							<FormattedMessage id="page.footer.help" />
						</Link>
					</li>

					<li>
						<Link to={{pathname: '/accessibility'}}>
							<FormattedMessage id="page.footer.accessibility" />
						</Link>
					</li>

					<li>
						<Link to={{pathname: '/mentions-legales'}}>
							<FormattedMessage id="page.menu.legalMentions" />
						</Link>
					</li>
				</ul>
			</div>
		</article>
	);
}

SiteMap.contextTypes = {
	intl: intlShape
};
