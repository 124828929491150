/**
 *
 */
export const SET_ACTIVE_DISCUSSION = 'chat/SET_ACTIVE_DISCUSSION';
export const CREATE_DISCUSSION = 'chat/CREATE_DISCUSSION';


/**
 *
 */
export const setActiveDiscussion = (id) => ({
	type: SET_ACTIVE_DISCUSSION,
	payload: {
		id
	}
});

/**
 *
 */
export const createDiscussion = (chatId, user) => ({
	type: CREATE_DISCUSSION,
	payload: {
		chatId,
		user
	}
});
