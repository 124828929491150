import React from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import getLoginUrl from '../../loginUrl';

export default function AppointmentsNotConnected({}, {intl}) {
	const title = intl.formatMessage({
		id: 'page.appointments.title'
	});

	return (
		<div className="page-appointments">
			<Helmet title={title} />

			<aside className="page-appointments-aside">
				<header className="page-appointments-header">
					<h1 className="page-appointments-title">
						{title}
					</h1>
				</header>
			</aside>

			<div className="page-appointments-main">
				<p>
					<FormattedMessage id="page.appointments.not_connected.text" />
				</p>

				<div className="page-appointments-main-button">
					<a
						className="form-button button button-large button-primary link-button button-largeText"
						href={getLoginUrl()}
					>
						<FormattedMessage id="page.home.appointments.not_connected.link" />
					</a>
				</div>
			</div>
		</div>
	)
}

AppointmentsNotConnected.contextTypes = {
	intl: intlShape
};
