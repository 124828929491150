import {groupBy, mapValues, flatMap, sortBy} from 'lodash';
import moment from 'moment';
import {DATE_FORMAT} from '../api/dates';



/**
 *	Les points (1), (2) etc réfèrent à des explications en
 *	dessous de la fonction.
 */
export function groupAvailableSlotsByDate(allocations /* (1) */, duration) {
	const keyed /* (2) */ = groupBy(allocations, (allocation) =>
		moment(allocation.date).format(DATE_FORMAT)
	);

	return /* (6) */ mapValues(keyed, (group /* (3) */) => {
		const slots /* (4) */ = flatMap(group, (allocation) =>
			allocation.availability_slots.map(({date_time}) => ({
				allocationId: allocation.id,
				dateRange: {
					start: moment(date_time),
					end: moment(date_time).add(duration, 'minutes')
				}
			}))
		);

		return /* (5) */ sortBy(slots, (slot) =>
			slot.dateRange.start.unix()
		);
	});
}

/*

(1) - Allocations depuis l'API

	allocations = [
		{
			"id": "6322b5bb-fd72-11e5-ac21-0242ac11001",
			"date": "2016-06-10T00:00:00+0100",
			"availability_slots": [
				{
					"date_time": "2016-06-10T10:00:00+01:00",
					"quantity": 1
				},
				{
					"date_time": "2016-06-10T11:00:00+01:00",
					"quantity": 1
				}
			]
		}
	];

(2) - Allocations groupées par date

	keyed = {
		"2016-06-10": [
			{
				"id": "6322b5bb-fd72-11e5-ac21-0242ac11001",
				"date": "2016-06-10T00:00:00+0100",
				"availability_slots": [
					{
						"date_time": "2016-06-10T10:00:00+01:00",
						"quantity": 1
					},
					{
						"date_time": "2016-06-10T11:00:00+01:00",
						"quantity": 1
					}
				]
			}
		]
	};

(3) - Un seul groupe d'allocations

	group = [
		{
			"id": "6322b5bb-fd72-11e5-ac21-0242ac11001",
			"date": "2016-06-10T00:00:00+0100",
			"availability_slots": [
				{
					"date_time": "2016-06-10T10:00:00+01:00",
					"quantity": 1
				},
				{
					"date_time": "2016-06-10T11:00:00+01:00",
					"quantity": 1
				}
			]
		}
	];

(4) - Tous les slots du groupe d'allocations

	slots = [
		{
			"allocationId": "6322b5bb-fd72-11e5-ac21-0242ac11001",
			"dateRange": {
				"start": moment(),
				"end": moment()
			}
		},
		{
			"allocationId": "6322b5bb-fd72-11e5-ac21-0242ac11001",
			"dateRange": {
				"start": moment(),
				"end": moment()
			}
		}
	];

(5) - La même chose que (4) triès par date de début

(6) - Tous les slots indexés par date

	slots = {
		"2016-06-10": [
			{
				"allocationId": "6322b5bb-fd72-11e5-ac21-0242ac11001",
				"dateRange": {
					"start": moment(),
					"end": moment()
				}
			},
			{
				"allocationId": "6322b5bb-fd72-11e5-ac21-0242ac11001",
				"dateRange": {
					"start": moment(),
					"end": moment()
				}
			}
		]
	};

*/
