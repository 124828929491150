export const Languages = ['fr'];
export const DefaultLanguage = 'fr';

const Locales = {
	'fr': 'fr',
	'es': 'es',
	'en': 'en-gb'
};

export const getLocale = () => {
	const lang = currentLanguage();
	return Locales[lang];
}

export const Formats = {
	date: {
		day: {
			timeZone: 'Europe/Paris',
			month: 'short',
			day: '2-digit'
		},
		date: {
			timeZone: 'Europe/Paris',
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		},
		longDate: {
			timeZone: 'Europe/Paris',
			year: 'numeric',
			month: 'long',
			day: '2-digit'
		}
	},
	time: {
		slot: {
			timeZone: 'Europe/Paris',
			hour: '2-digit',
			minute: '2-digit'
		}
	}
};

export const currentLanguage = () => {
	const matches = document.location.pathname
		.replace(window.rdv.basePath, '')
		.match(/^\/([a-z]{2})/i);
	return matches && matches.length
		? matches[1]
		: DefaultLanguage;
}
