import {debounce} from 'lodash';
import {put, call} from 'redux-saga/effects';
import {currentPlatform} from '../api/env';
import {setPlatform} from '../actions/env';



/**
 *
 */
const waitForResize = () =>
	new Promise((resolve) => {
		window.onresize = debounce(resolve, 300);
	});



/**
 *
 */
export function* watchResize() {
	while (true) { // eslint-disable-line no-constant-condition
		yield call(waitForResize);
		yield put(setPlatform(currentPlatform()));
	}
}
