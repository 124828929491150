import appHistory from './history';

let loginUrl = '';
appHistory.listen((location, action) => {
		const loginData = window.rdv.login;

		let redirectUri = loginData.basePath + location.pathname;
		if (location.search !== '') {
			redirectUri += location.search;
		}

		loginUrl = loginData.url
			+ '?'
			+ loginData.param
			+ '='
			+ encodeURIComponent(redirectUri);
});

export default function getLoginUrl() {
	return loginUrl;
}
