import React from 'react';
import {locationShape} from 'react-router';
import {intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import SearchAsideContainer from './SearchAsideContainer';
import SearchMainContainer from './SearchMainContainer';
import ChatButtonContainer from './ChatButtonContainer';



/**
 *
 */
export default function Search({location}, {intl}) {
	const title = intl.formatMessage({
		id: 'page.search.title'
	});

	return (
		<article className="page-search">
			<Helmet title={title} />

			<div className="page-search-aside">
				<header className="page-search-header">
					<h1 className="page-search-title">
						{title}
					</h1>
				</header>

				<SearchAsideContainer
					placeId={location.query.place}
					topicId={location.query.topic}
				/>
			</div>

			<div className="page-search-main">
				<SearchMainContainer />
			</div>

			<ChatButtonContainer />

		</article>
	);
}

Search.propTypes = {
	location: locationShape
};

Search.contextTypes = {
	intl: intlShape
};
