import {takeLatest} from 'redux-saga';
import {put, select} from 'redux-saga/effects';
import {some} from 'lodash';
import {VALIDATE, updateStatus} from '../actions/forms';
import {STATUS_VALID, STATUS_INVALID} from '../api/forms';



/**
 *
 */
function* updateStatusWorker({payload: {form}}) {
	const fields = yield select(
		({forms}) => forms[form].fields
	);

	const hasError = ({error}) => !!error;
	const status = some(fields, hasError)
		? STATUS_INVALID
		: STATUS_VALID;

	yield put(updateStatus(form, status));
}



/**
 *	Updates status after validation.
 */
export function* watchValidate() {
	yield* takeLatest(VALIDATE, updateStatusWorker);
}
