import React, {PropTypes} from 'react';
import {PLATFORM_DESKTOP} from '../api/env';



/**
 *
 */
export default function Table({platform, rows, makeRow, caption, children}) {
	return (platform === PLATFORM_DESKTOP)
		? <DesktopTable headers={children} rows={rows} makeRow={makeRow} caption={caption} />
		: <MobileTable headers={children} rows={rows} makeRow={makeRow} caption={caption} />;
}

Table.propTypes = {
	platform: PropTypes.string.isRequired,
	rows: PropTypes.array.isRequired,
	makeRow: PropTypes.func.isRequired,
	children: PropTypes.array.isRequired,
	caption: PropTypes.string.isRequired
};



/**
 *
 */
function DesktopTable({headers, rows, makeRow, caption}) {
	return (
		<table className="table">
			<caption>{caption}</caption>
			<thead>
				<tr>
					{headers.map((header, headerIndex) => (
						<th scope="col" key={`header-${headerIndex}`}>
							{header}
						</th>
					))}
				</tr>
			</thead>

			<tbody>
				{rows.map((row, rowIndex) => (
					<tr key={`row-${rowIndex}`}>
						{makeRow(row).map((cell, cellIndex) => (
							<td key={`cell-${cellIndex}`}>
								{cell}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
}

DesktopTable.propTypes = {
	headers: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	makeRow: PropTypes.func.isRequired,
	caption: PropTypes.string.isRequired
};



/**
 *
 */
function MobileTable({headers, rows, makeRow, caption}) {
	return (
		<div>
			{rows.map((row, rowIndex) => (
				<table key={`row-${rowIndex}`} className="table mobile-table">
					<caption>{`${caption}-${rowIndex + 1}`}</caption>
					<tbody>
						{makeRow(row).map((cell, cellIndex) => (
							<tr key={`cell-${cellIndex}`}>
								<th scope="row">{headers[cellIndex]}</th>
								<td>{cell}</td>
							</tr>
						))}
					</tbody>
				</table>
			))}
		</div>
	);
}

MobileTable.propTypes = {
	headers: PropTypes.array.isRequired,
	rows: PropTypes.array.isRequired,
	makeRow: PropTypes.func.isRequired,
	caption: PropTypes.string.isRequired
};
