import {useRouterHistory} from 'react-router';
import createBrowserHistory from 'history/lib/createBrowserHistory';



/**
 *
 */
const createAppHistory = useRouterHistory(createBrowserHistory);
const appHistory = createAppHistory({
	basename: window.rdv.basePath
});



export default appHistory;
