import React, {Component, PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import Modal from '../Modal';



/**
 *
 */
export default class ReportModal extends Component {

	static propTypes = {
		appointment: PropTypes.object
	};

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	show() {
		this.setState({
			isOpen: true
		});
	}

	hide() {
		this.setState({
			isOpen: false
		});
	}

	render() {
		const {appointment} = this.props;
		let button;
		let modal;

		if (appointment.student_report) {
			button = (
				<button className="button button-secondary" onClick={::this.show}>
					<FormattedMessage id="appointments.report.modal.trigger" />
				</button>
			);
		}

		if (this.state.isOpen) {
			const title = (
				<FormattedMessage id="appointments.report.modal.title" />
			);

			modal = (
				<Modal title={title} onClose={::this.hide}>
					<div className="modal-body">
						<p>{appointment.student_report}</p>
					</div>

					<div className="modal-footer">
						<button
							className="button button-large button-secondary"
							onClick={::this.hide}
						>
							<FormattedMessage id="appointments.report.modal.close" />
						</button>
					</div>
				</Modal>
			);
		}

		return (
			<div>
				{button}
				{modal}
			</div>
		);
	}
}
