import React, {PropTypes} from 'react';



/**
 * @see https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
 */
export default function MultilineText({text}) {
	return (
		<span>
			{text.split('\n').map((line, key) => (
				<span key={key}>{line}<br /></span>
			))}
		</span>
	);
}

MultilineText.propTypes = {
	text: PropTypes.string.isRequired
};
