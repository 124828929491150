import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import renderIf from 'render-if';
import {isEmpty} from 'lodash';
import Loadable from '../Loadable';
import ResultContainer from './ResultContainer';
import InfoContainer from './InfoContainer';



/**
 *
 */
export default function Results({loading, subjects, info, ...props}) {
	const hasSubjects = subjects.length;
	const hasInfo = info.length;

	return (
		<Loadable loading={loading}>
			<div className="search-results">
				{renderIf(!hasSubjects && !hasInfo)(() => (
					<p className="search-results-message">
						<FormattedMessage
							id="page.search.results.empty"
							values={{loading}}
						/>
					</p>
				))}

				{renderIf(hasInfo)(() =>
					info.map((block) => (
						<InfoContainer
							key={block.id}
							{...block}
						/>
					))
				)}

				{renderIf(hasSubjects)(() =>
					subjects.map((subject) => (
						<ResultContainer
							key={subject.id}
							hasAllocations={!isEmpty(subject.allocations)}
							{...props}
							{...subject}
						/>
					))
				)}
			</div>
		</Loadable>
	);
}

Results.propTypes = {
	loading: PropTypes.bool,
	subjects: PropTypes.array
};
