import {createStore, compose, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga';
import {identity} from 'lodash';



/**
 *
 */
export default (reducers, sagas) => {
	const setupDevTools = (
		process.env.NODE_ENV !== 'production'
		&& window.__REDUX_DEVTOOLS_EXTENSION__
	);

	const sagaMiddleware = createSagaMiddleware();
	const devToolsMiddleware = setupDevTools
		? window.__REDUX_DEVTOOLS_EXTENSION__()
		: identity;

	const store = createStore(
		reducers,
		compose(
			applyMiddleware(sagaMiddleware),
			devToolsMiddleware
		)
	);

	sagaMiddleware.run(sagas);
	return store;
};
