import {connect} from 'react-redux';
import {GROUP_ARCHIVED} from '../../api/appointments';
import ArchivedAppointments from './ArchivedAppointments';



/**
 *
 */
const mapStateToProps = ({appointments}) => ({
	loading: appointments.loading,
	appointments: appointments.groups[GROUP_ARCHIVED] || []
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ArchivedAppointments);
