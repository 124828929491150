import React, {PropTypes} from 'react';
import {injectIntl, intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import ActiveDiscussionContainer from './ActiveDiscussionContainer';



/**
 *
 */
function Chat({params, intl}) {
	const title = intl.formatMessage({
		id: 'page.chat.title'
	});

	return (
		<div className="page-chat">
			<Helmet title={title} />

			<h1 lang="en" className="page-chat-title">
				{title}
			</h1>
			<ActiveDiscussionContainer chatId={params.id} />
		</div>
	);
}

Chat.propTypes = {
	params: PropTypes.object.isRequired,
	intl: intlShape
};

export default injectIntl(Chat);
