import {combineReducers} from 'redux';
import {reducers as chatReducers} from 'vtech-chat';
import chat from './chat';
import appointments from './appointments';
import env from './env';
import flash from './flash';
import forms from './forms';
import home from './home';
import search from './search';
import user from './user';



/**
 *
 */
const reducers = combineReducers({
	...chatReducers,
	chat,
	appointments,
	env,
	flash,
	forms,
	home,
	search,
	user
});



export default reducers;
