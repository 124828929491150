import React, {PropTypes} from 'react';
import {injectIntl, intlShape, FormattedMessage} from 'react-intl';
import renderIf from 'render-if';
import classNames from 'classnames';
import { ResultTitle, ResultDetail } from "./Result";



function Info({name, content, phone, email, url, url_title: urlLabel, open, onToggle, intl}) {
	const className = classNames({
		'search-result': true,
		'search-result-closed': !open,
	});

	return (
		<article className={className}>
			<ResultTitle>
				{name}
			</ResultTitle>

			<div className="search-result-body">
				<div className="search-result-summary search-result-summary--info">
					<img
						className="search-result-icon"
						src={`${window.rdv.bundleBasePath}/img/info.svg`}
						alt=""
					/>
					<h3 className="search-result-info-title reset-title-styles">
						<FormattedMessage id="page.search.info_block.title" />
					</h3>
				</div>

				<ResultDetail description={content} />

				{renderIf(open && phone)(() => (
					<InfoItem title="page.search.info_block.contact_number">
						{phone}
					</InfoItem>
				))}

				{renderIf(open && email)(() => (
					<InfoItem title="page.search.info_block.contact_email">
						{email}
					</InfoItem>
				))}

				{renderIf(open && url)(() => {
					const newWindowText = intl.formatMessage({
						id: 'a11y.new_window'
					});
					const emptyUrlTitle = intl.formatMessage({
						id: 'page.search.info_block.url_without_label'
					});
					const urlTitle = `${urlLabel || emptyUrlTitle} - ${newWindowText}`
					return (
						<InfoItem title="page.search.info_block.url">
							<a
								href={url}
								aria-label={urlTitle}
								title={urlTitle}
							>
								{urlLabel || url}
							</a>
						</InfoItem>
					)
				})}

				<button
					className="search-result-detail-toggle"
					onClick={onToggle}
				>
					<FormattedMessage
						id="page.search.info_block.toggle"
						values={{open}}
					/>
				</button>
			</div>
		</article>
	);
}

Info.propTypes = {
	name: PropTypes.string,
	description: PropTypes.string,
	phone: PropTypes.string,
	email: PropTypes.string,
	url: PropTypes.string,
	urlLabel: PropTypes.string,
	open: PropTypes.bool,
	onToggle: PropTypes.func,
	intl: intlShape,
};

const InfoItem = ({title, children}) => {
	return (
		<div>
			<h4 className="search-result-subtitle">
				<FormattedMessage id={title} />
			</h4>
			<p className="search-result-description">
				{children}
			</p>
		</div>
	)
}

InfoItem.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export default injectIntl(Info);
