import {compose, withState, mapProps} from 'recompose';
import FiltersForm from './FiltersForm';



/**
 *
 */
export default compose(
	withState('open', 'setOpen', false),
	mapProps(({setOpen, ...props}) => ({
		...props,
		onToggle() {
			setOpen((open) => !open);
		}
	}))
)(FiltersForm);
