import {connect} from 'react-redux';
import {cancelAppointment} from '../../actions/appointments';
import {GROUP_SCHEDULED} from '../../api/appointments';
import ScheduledAppointments from './ScheduledAppointments';



/**
 *
 */
const mapStateToProps = ({appointments}) => ({
	loading: appointments.loading,
	appointments: appointments.groups[GROUP_SCHEDULED] || []
});

/**
 *
 */
const mapDispatchToProps = (dispatch) => ({
	cancelAppointment(id) {
		dispatch(cancelAppointment(id));
	}
});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScheduledAppointments);
