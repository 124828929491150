import React, {Component, PropTypes} from 'react';
import {min} from "lodash";
import classNames from "classnames";
import Downshift from "downshift";




export default class Autocomplete extends Component {
	constructor(props) {
		super(props);
		this.onStateChange = this.onStateChange.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.handleSuggestionsPosition = this.handleSuggestionsPosition.bind(this);
		this.textInputRef = null;
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleSuggestionsPosition);
		window.addEventListener('scroll', this.handleSuggestionsPosition);
	}

	componentDidUpdate() {
		if (this.props.shouldFocus && this.textInputRef) {
			this.textInputRef.focus();
		}

		this.handleSuggestionsPosition();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleSuggestionsPosition);
		window.removeEventListener('scroll', this.handleSuggestionsPosition);
	}

	/**
	 * calculate where to put the suggestions box:
	 * if there is no space below the input in the viewport, we put it above
	 */
	handleSuggestionsPosition() {
		// the max css height of suggestions is around 240 px (8 items * 30px each)
		const suggestionsHeight = min([this.props.suggestions.length * 30, 240]);
		const hasSpaceBelow = window.innerHeight - this.containerRef.getBoundingClientRect().bottom - suggestionsHeight > 0;
		if (hasSpaceBelow) {
			this.containerRef.classList.remove('form-autocomplete--above');
		} else {
			this.containerRef.classList.add('form-autocomplete--above');
		}
	}

	onStateChange(changes, {selectedItem}) {
		/**
		 * by default, downshift clears the input when the users leaves it
		 * without selecting a suggestion. This changes this behavior in order
		 * to keep user input even when typing on escape or clicking outside the input.
		 *
		 * We only update the value on user input or user selection.
		 */
		if (
			changes.hasOwnProperty('inputValue')
			&& ([
				Downshift.stateChangeTypes.changeInput,
				Downshift.stateChangeTypes.clickItem,
				Downshift.stateChangeTypes.keyDownEnter,
			].includes(changes.type))
		) {
			this.props.onValueChange(changes.inputValue);

			if (!this.props.onSelectedSuggestionChange) {
				return;
			}
			const actuallySelectedItem = selectedItem && changes.inputValue === this.props.suggestionToString(selectedItem)
				? selectedItem
				: null;
			this.props.onSelectedSuggestionChange(actuallySelectedItem);
		}
	}

	onFocus(event, {isOpen, selectedItem, openMenu}) {
		if (this.props.onFocus) {
			this.props.onFocus(event);
			return;
		}

		/*
		 * If the user started to type "ange", then unfocused the field,
		 * then focused the field again, we want to show him directly
		 * the suggestions (ie. "Angers"), to make it easier for him to select one
		 */
		if (!isOpen
			&& this.props.suggestions.length
			&& (!selectedItem || this.props.value !== this.props.suggestionToString(selectedItem))
		) {
			openMenu();
		}
	}

	render() {
		const {
			suggestions,
			suggestionRenderer,
			suggestionToString,
			suggestionToKey,
			onSelectedSuggestionChange,
			onValueChange,
			containerRef,
			shouldFocus,
			...inputProps
		} = this.props;

		const downshiftProps = {
			itemToString: suggestionToString,
			inputValue: inputProps.value,
			inputId: inputProps.id || inputProps.name,
			onStateChange: this.onStateChange
		};

		return (
			<Downshift {...downshiftProps}>
				{(downshiftProps) => {
					const {
						getRootProps,
						getInputProps,
						getMenuProps,
						getItemProps,
						isOpen,
						highlightedIndex,
					} = downshiftProps;
					return (
						<div
							className="form-autocomplete"
							ref={div => {
								this.containerRef = div;
							}}>
							<div {...getRootProps({}, {suppressRefError: true})}>
								<input
									{...getInputProps({
										...inputProps,
										onFocus: (event) => this.onFocus(event, downshiftProps)
									})}
									ref={(node) => {
										this.textInputRef = node;
									}}
								/>
							</div>
							<ul {...getMenuProps()} className="form-autocomplete-suggestions">
								{ isOpen && suggestions.map((suggestion, index) => (
									<li
										className={classNames({
											"form-autocomplete-suggestion-item": true,
											"form-autocomplete-suggestion-item--active": highlightedIndex === index,
										})}
										key={suggestionToKey
											? suggestionToKey(suggestion)
											: suggestionToString(suggestion)
										}
										{...getItemProps({ item: suggestion, index })}
									>
										{suggestionToString(suggestion)}
									</li>
								))}
							</ul>
						</div>
					);
				}}
			</Downshift>
		);
	}
}

Autocomplete.propTypes = {
	/* value to show at all times in the input */
	value: PropTypes.string.isRequired,
	/* handler that is called when the component wants to update the value */
	onValueChange: PropTypes.func.isRequired,
	/* handler that is called when a suggestion is selected by the user */
	onSelectedSuggestionChange: PropTypes.func,
	/* array of suggestion items to show for the current value */
	suggestions: PropTypes.array.isRequired,
	/* function taking a suggestion item and returning a string representing it.
	It's used in the suggestions list */
	suggestionToString: PropTypes.func.isRequired,
	/* function taking a suggestion item and returning its `key` prop.
	if not passed, suggestionToString is used to generate the key */
	suggestionToKey: PropTypes.func,
	shouldFocus: PropTypes.bool
};

Autocomplete.defaultProps = {
	shouldFocus: false
};
