import React from 'react';
import Summary from './Summary';
import Results from './Results';



/**
 *
 */
export default function SearchMain(props) {
	return (
		<div>
			<Summary {...props} />
			<Results {...props} />
		</div>
	);
}
