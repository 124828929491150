import {connect} from 'react-redux';
import React from 'react';
import {isEmpty} from 'lodash';
import {submitAppointment, cancelAppointment} from '../../actions/search';
import AppointmentModal from './AppointmentModal';
import AppointmentNotConnectedModal from './AppointmentNotConnectedModal';
import {assign} from 'lodash';

const AppointmentModalComponent = (props) => {
	return (
		<div>
			{isEmpty(props.user)
				? <AppointmentNotConnectedModal subjectId={props.subjectId} cancel={props.cancelAppointment} />
				: <AppointmentModal {...props} />
			}
		</div>
	)
}

/**
 *
 */
function mapStateToProps({search, user}) {
	let props = assign(search.appointment, {subjects: search.subjects});
	props.user = user;
	return props;
}

/**
 *
 */
function mapDispatchToProps(dispatch) {
	return {
		submitAppointment(subjectId, slot, data) {
			dispatch(submitAppointment(subjectId, slot, data));
		},
		cancelAppointment() {
			dispatch(cancelAppointment());
		}
	};
}



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AppointmentModalComponent);
