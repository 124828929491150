import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import {findDateRange} from '../../api/appointments';



/**
 *
 */
export default function AppointmentTime({appointment}) {
	const range = findDateRange(appointment);

	return (
		<FormattedMessage
			id="appointments.time"
			values={{
				start: range.start.format('HH:mm'),
				end: range.end.format('HH:mm')
			}}
		/>
	);
}

AppointmentTime.propTypes = {
	appointment: PropTypes.object
};
