import React, {PropTypes} from 'react';
import {intlShape} from 'react-intl';
import {Link} from './Link';

const ActiveLink = ({children, title, to, className, router}, {intl}) => {
	const {pathname, query} = to;
	const activeTitle = title.concat(intl.formatMessage({id: 'link.active'}));

	return (
		<Link
			to={{pathname, query}}
			className={className}
			title={router.isActive({pathname: `${intl.locale}${to.pathname}`, query}) ? activeTitle : undefined}
			withQuery
		>
			{children}
		</Link>
	);
};

ActiveLink.propTypes = {
	children: PropTypes.element,
	title: PropTypes.string,
	to: PropTypes.object,
	router: PropTypes.object,
	className: PropTypes.string
};

ActiveLink.contextTypes = {
	intl: intlShape
};

export default ActiveLink;
