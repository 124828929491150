import {connect} from 'react-redux';
import {fetchAppointments} from '../../actions/appointments';
import AppointmentsMenu from './AppointmentsMenu';



/**
 *
 */
const mapStateToProps = ({user, appointments}) => ({
	user,
	groups: appointments.groups
});

/**
 *
 */
const mapDispatchToProps = (dispatch) => ({
	fetchAppointments(userId) {
		dispatch(fetchAppointments(userId));
	}
});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AppointmentsMenu);
