import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import {partial} from 'lodash';
import FormField from '../FormField';



/**
 *
 */
export default function AppointmentFormAttachments(
	{attachments, onAddAttachment, onRemoveAttachment},
	{intl}
) {
	return (
		<fieldset className="form-fieldset Attachments">
			<legend className="form-legend form-field-title">
				<FormattedMessage id="appointment.create.form.label.attachments" />
			</legend>

			<button
				type="button"
				role="button"
				className="Attachments-addButton"
				onClick={onAddAttachment}
			>
				<FormattedMessage id="appointment.create.form.action.add_attachment" />
			</button>

			<ul className="Attachments-list">
				{attachments.map((id, index) => (
					<li className="Attachments-item" key={id}>
						<FormField
							name={`attachments[${index}]`}
							label={intl.formatMessage({
								id: 'appointment.create.form.label.attachment'
							}, {
								index: index + 1
							})}
						>
							<input type="file" />
						</FormField>

						<button
							type="button"
							role="button"
							className="Attachments-removeButton"
							onClick={partial(onRemoveAttachment, id)}
							title={intl.formatMessage({
								id: 'appointment.create.form.action.remove_attachment.title'
							})}
						>
							<FormattedMessage id="appointment.create.form.action.remove_attachment" />
						</button>
					</li>
				))}
			</ul>
		</fieldset>
	);
}

AppointmentFormAttachments.contextTypes = {
	intl: intlShape
};

AppointmentFormAttachments.propTypes = {
	attachments: PropTypes.array.isRequired,
	onAddAttachment: PropTypes.func.isRequired,
	onRemoveAttachment: PropTypes.func.isRequired
};
