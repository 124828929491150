/**
 *
 */
export const SET_PLATFORM = 'env/SET_PLATFORM';



/**
 *
 */
export const setPlatform = (platform) => ({
	type: SET_PLATFORM,
	payload: {
		platform
	}
});
