import React from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import SearchContainer from './SearchContainer';
import AppointmentsContainer from './AppointmentsContainer';



/**
 *
 */
export default function Home(_, {intl}) {
	const title = intl.formatMessage({
		id: 'page.home.title'
	});

	return (
		<div className="page-home">
			<Helmet title={title} />

			<article className="page-home-section page-home-section-search">
				<header className="page-home-section-header">
					<h1 className="page-home-section-title">
						<FormattedMessage id="page.home.search.title" />
					</h1>
				</header>

				<SearchContainer />
			</article>

			<article className="page-home-section page-home-section-appointments">
				<header className="page-home-section-header">
					<h1 className="page-home-section-title">
						<FormattedMessage id="page.home.appointments.title" />
					</h1>
				</header>

				<AppointmentsContainer />
			</article>
		</div>
	);
}

Home.contextTypes = {
	intl: intlShape
};
