import {connect} from 'react-redux';
import {compose, withProps, withState} from 'recompose';
import {updateProp} from '../../actions/forms';
import EstablishmentModal from './EstablishmentModal';
import {PLACE_EMPTY_UUID} from "../../constants";

const mapStateToProps = () => ({});

function mapDispatchToProps(dispatch) {
	return {
		/**
		 * Met à jour la sélection de la ville / code postal, on va proposer dans la liste des établissements ceux qui
		 * correspondent au code postal uniquement.
		 *
		 * @param {string} value
		 */
		onTownOrPostalCodeChange(value) {
			dispatch(updateProp('home', 'establishment', 'townOrPostalCode', value));
		},

		onHelp() {
			dispatch(updateProp('home', 'establishment', 'placeUuid', PLACE_EMPTY_UUID));
		},

		cancelSelection() {
			dispatch(updateProp('home', 'establishment', 'value', ''));
			dispatch(updateProp('home', 'establishment', 'uuid', null));
			dispatch(updateProp('home', 'establishment', 'townOrPostalCode', ''));
			dispatch(updateProp('home', 'establishment', 'placeUuid', null));
		}
	};
}

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withState('isOpen', 'setOpen', false),
	withProps(({setOpen, cancelSelection, onToggleState}) => ({
		onOpen() {
			setOpen(true);
			onToggleState(true);
		},
		onClose() {
			setOpen(false);
			onToggleState(false);
			cancelSelection();
		}
	})),
);

export default enhance(EstablishmentModal);
