import React from 'react';
import {intlShape, FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import Helmet from 'react-helmet';
import {Link} from '../Link';

/**
 *
 */
export default function NotFound(props, {intl}) {
	const pageTitle = intl.formatMessage({
		id: 'page.404.title'
	});

	return (
		<article className="Page">
			<Helmet title={pageTitle} />

			<header className="Page-header">
				<h1>
					<FormattedMessage id="page.404.title" />
				</h1>
			</header>

			<div className="Page-body">
				<FormattedHTMLMessage id="page.404.body" tagName="div" />
				<Link to={{pathname: '/'}}>
					<FormattedMessage id="page.404.backLink" />
				</Link>.
			</div>
		</article>
	);
}

NotFound.contextTypes = {
	intl: intlShape
};
