import {UPDATE_TOPICS_LOADING, UPDATE_PLACES_LOADING} from '../actions/home';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	placesLoading: false,
	topicsLoading: false
};

/**
 *
 */
const updatePlacesLoading = (state, {loading}) => ({
	...state,
	placesLoading: loading
});


/**
 *
 */
const updateTopicsLoading = (state, {loading}) => ({
	...state,
	topicsLoading: loading
});



/**
 *
 */
export default createReducer(initialState, {
	[UPDATE_PLACES_LOADING]: updatePlacesLoading,
	[UPDATE_TOPICS_LOADING]: updateTopicsLoading
});
