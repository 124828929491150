import React, {Component, PropTypes} from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import {last} from 'lodash';
import {DefaultLanguage} from '../api/locale';
import {Link, IndexLink} from './Link';
import TranslationsDisclaimer from './TranslationsDisclaimer';
import FlashContainer from './FlashContainer';
import CookieWarningContainer from './CookieWarningContainer';
import UserMenuContainer from './UserMenuContainer';
import Breadcrumbs from './Breadcrumbs';
import {isEmpty} from 'lodash';



/**
 *
 */
export default class Layout extends Component {

	static propTypes = {
		children: PropTypes.element.isRequired,
		routes: PropTypes.array,
		params: PropTypes.object
	};

	static contextTypes = {
		intl: intlShape
	};

	constructor(props) {
		super(props);

		/**
		 * Hide login and logout links from index.html
		 */
		document.getElementById('loginContainer').style.display = 'none';

		this.updateRouteClass(this.props);
		this.state = {
			isMenuOpen: false
		};

		this.handleToggle = this.handleToggle.bind(this);
		this.handleMenuFocusTrap = this.handleMenuFocusTrap.bind(this);
	}

	componentWillReceiveProps(props) {
		this.updateRouteClass(props);
	}

	/**
	 *  Adds a class to <html> to allow for page-specific styles.
	 */
	updateRouteClass({routes}) {
		const route = last(routes);
		const routeName = (route && route.name)
			? route.name
			: '';

		document.documentElement.className = `route-${routeName}`;
	}

	handleToggle() {
		this.setState(({isMenuOpen}) => ({
			isMenuOpen: !isMenuOpen
		}));
	}

	handleMenuFocusTrap(event) {
		if (this.state.isMenuOpen) {
			const toggleButton = document.getElementById('menu-toggle-button');
			const lastFocusableElement = document.getElementById('user-menu-link');

			if (document.activeElement === lastFocusableElement
				&& (!event.shiftKey && event.keyCode === 9) // Only tab pressed
			) {
				event.preventDefault();
				toggleButton.focus();
			}

			if (document.activeElement === toggleButton
				&& (event.shiftKey && event.keyCode === 9) // tab and shift pressed
			) {
				event.preventDefault();
				lastFocusableElement.focus();
			}
		}
	}

	render() {
		const {routes, params, location} = this.props;
		const isInIframe = !!location.query.iframe;
		const className = classNames('page', {
			'page-menus-open': this.state.isMenuOpen,
			'page-in-iframe': isInIframe
		});

		const toggleText = this.context.intl.formatMessage({
			id: this.state.isMenuOpen
				? 'page.header.close_menu'
				: 'page.header.open_menu'
		});

		return (
			<div className={className}>
				{this.props.params.lang !== DefaultLanguage ? (
					<TranslationsDisclaimer />
				) : null}

				<div className="page-inner">
					<CookieWarningContainer />

					<ul id="a11y-menu" className="a11y-menu">
						<li>
							<a href="#nav">
								<FormattedMessage id="a11y.menu.link.nav" />
							</a>
						</li>
						<li>
							<a href="#main">
								<FormattedMessage id="a11y.menu.link.content" />
							</a>
						</li>
					</ul>

					<Helmet
						htmlAttributes={{ lang: params.lang }}
						title="Crous rendez-vous"
						titleTemplate="%s - Crous rendez-vous"
					/>

					<FlashContainer />

					<div className="page-content-wrapper">
						<div className="page-header">
							<Header isInIframe={isInIframe} intl={this.context.intl} />

							<div className="page-menus-toggle">
								<button
									className="page-menus-toggle-button"
									onClick={this.handleToggle}
									onKeyDown={this.handleMenuFocusTrap}
									title={toggleText}
									aria-expanded={this.state.isMenuOpen}
									aria-controls="page-menus"
									id='menu-toggle-button'
								>
									{toggleText}
								</button>
							</div>

							<div className="page-menus" id="page-menus" onKeyDown={this.handleMenuFocusTrap}>
								<PageMenu currentRoute={last(routes)} onClose={this.handleToggle} />
								<UserMenuContainer />
							</div>

							<Breadcrumbs routes={routes} lang={params.lang} />
						</div>

						<main className="page-main" role="main" id="main">
							{this.props.children}
						</main>
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}



/**
 *
 */
function Header({isInIframe, intl}) {
	return (
		<header className="page-branding" role="banner" id="header">
			{isInIframe
				? (
					<p className="page-brand">
						<FormattedMessage id="page.header.title" />
					</p>
				)
				: (
					<IndexLink
						className="page-brand"
						to={{pathname: '/'}}
						title={intl.formatMessage({
							id: 'page.header.title.title'
						})}
					>
						<FormattedMessage id="page.header.title" />
					</IndexLink>
				)
			}
		</header>
	);
}

Header.propTypes = {
	isInIframe: PropTypes.bool.isRequired
};

/**
 *
 */
function Footer() {
	return (
		<footer className="page-footer" role="contentinfo" id="footer">
			<div className="page-footer-inner">
				<ul className="page-footer-links">
					<li>
						<Link to={{pathname: '/help'}}>
							<FormattedMessage id="page.footer.help" />
						</Link>
					</li>
					<li>
						<Link to={{pathname: '/accessibility'}}>
							<FormattedMessage id="page.footer.accessibility" />
						</Link>
					</li>
					<li>
						<Link to={{pathname: '/mentions-legales'}}>
							<FormattedMessage id="page.footer.legalMentions" />
						</Link>
					</li>
					<li>
						<Link to={{pathname: '/personal-data'}}>
							<FormattedMessage id="page.footer.personal-data" />
						</Link>
					</li>
					<li>
						<Link to={{pathname: '/contact'}}>
							<FormattedMessage id="page.footer.contact" />
						</Link>
					</li>
					<li>
						<Link to={{pathname: '/sitemap'}}>
							<FormattedMessage id="page.footer.sitemap" />
						</Link>
					</li>
				</ul>
				<p className="page-footer-a11y">
					<FormattedMessage id="page.footer.a11y" />
				</p>
			</div>
		</footer>
	);
}


function isOnAppointmentsPage(path) {
	return path
		? path.match(/^(scheduled|attended|cancelled|missed|postponed|archived)$/)
		: false;
}

/**
 *
 */
function PageMenu({currentRoute: {path}, onClose}, {intl}) {
	const indexTitle = !path
		? intl.formatMessage({id: 'page.menu.take_appointment.active'})
		: undefined;

	const appointmentsTitle = isOnAppointmentsPage(path)
		? intl.formatMessage({id: 'page.menu.my_appointments.title.active'})
		: intl.formatMessage({id: 'page.menu.my_appointments.title'});

	return (
		<nav className="page-menu" role="navigation" id="nav">
			<ul className="page-menu-items">
				<li className="page-menu-item">
					<IndexLink to={{pathname: '/'}} title={indexTitle} onClick={onClose}>
						<FormattedMessage id="page.menu.take_appointment" />
					</IndexLink>
				</li>

				<li className="page-menu-item">
					{isEmpty(window.rdv.user)
						? (
							<Link
								to={{pathname: '/login-appointments'}}
								title={appointmentsTitle}
								onClick={onClose}
								withQuery
							>
								<FormattedMessage id="page.menu.my_appointments" />
							</Link>
						)
						: (
							<Link
								to={{pathname: '/appointments'}}
								title={appointmentsTitle}
								onClick={onClose}
								withQuery
							>
								<FormattedMessage id="page.menu.my_appointments" />
							</Link>
						)
					}
				</li>
			</ul>
		</nav>
	);
}

PageMenu.contextTypes = {
	intl: intlShape
};

PageMenu.propTypes = {
	currentRoute: PropTypes.object
};
