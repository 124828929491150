/**
 *
 */
export const FETCH_APPOINTMENTS = 'appointments/FETCH_APPOINTMENTS';
export const CANCEL_APPOINTMENT = 'appointments/CANCEL_APPOINTMENT';
export const POSTPONE_APPOINTMENT = 'appointments/POSTPONE_APPOINTMENT';
export const UPDATE_APPOINTMENTS = 'appointments/UPDATE_APPOINTMENTS';
export const UPDATE_APPOINTMENTS_LOADING = 'appointments/UPDATE_APPOINTMENTS_LOADING';



/**
 *
 */
export const fetchAppointments = (userId) => ({
	type: FETCH_APPOINTMENTS,
	payload: {
		userId
	}
});

/**
 *
 */
export const cancelAppointment = (id) => ({
	type: CANCEL_APPOINTMENT,
	payload: {
		id
	}
});

/**
 *
 */
export const postponeAppointment = (id) => ({
	type: POSTPONE_APPOINTMENT,
	payload: {
		id
	}
});

/**
 *
 */
export const updateAppointments = (groups) => ({
	type: UPDATE_APPOINTMENTS,
	payload: {
		groups
	}
});

/**
 *
 */
export const updateAppointmentsLoading = (loading) => ({
	type: UPDATE_APPOINTMENTS_LOADING,
	payload: {
		loading
	}
});
