import {takeLatest} from 'redux-saga';
import {put, select, cancelled} from 'redux-saga/effects';
import {uniqueId} from 'lodash';
import {TYPE_SUCCESS, TYPE_ERROR} from '../api/flash';
import {fetchByUser, cancel, groupByStatus, postpone} from '../api/appointments';
import {pushMessage} from '../actions/flash';
import {
	FETCH_APPOINTMENTS,
	CANCEL_APPOINTMENT,
	POSTPONE_APPOINTMENT,
	fetchAppointments,
	updateAppointments,
	updateAppointmentsLoading
} from '../actions/appointments';



/**
 *
 */
function* fetchAppointmentsWorker({payload: {userId}}) {
	yield put(updateAppointmentsLoading(true));

	try {
		const appointments = yield fetchByUser(userId);
		const grouped = groupByStatus(appointments);

		yield put(updateAppointments(grouped));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'appointments.fetch.flash.error'
			));
		}
	}

	yield put(updateAppointmentsLoading(false));
}

/**
 *
 */
function* cancelAppointmentWorker({payload: {id}}) {
	try {
		yield cancel(id);
		yield put(pushMessage(
			uniqueId(),
			TYPE_SUCCESS,
			'appointments.cancel.flash.success'
		));

		const userId = yield select(({user}) => user.id);

		yield put(fetchAppointments(userId));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'appointments.cancel.flash.error'
			));
		}
	}
}

/**
 *
 */
function* postponeAppointmentWorker({payload: {id}}) {
	try {
		yield postpone(id);
		yield put(pushMessage(
			uniqueId(),
			TYPE_SUCCESS,
			'appointments.postpone.flash.success'
		));

		const userId = yield select(({user}) => user.id);

		yield put(fetchAppointments(userId));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'appointments.postpone.flash.error'
			));
		}
	}
}



/**
 *
 */
export function* watchFetchAppointments() {
	yield* takeLatest(FETCH_APPOINTMENTS, fetchAppointmentsWorker);
}

/**
 *
 */
export function* watchCancelAppointment() {
	yield* takeLatest(CANCEL_APPOINTMENT, cancelAppointmentWorker);
}

/**
 *
 */
export function* watchPostponeAppointment() {
	yield* takeLatest(POSTPONE_APPOINTMENT, postponeAppointmentWorker);
}
