import {compose, withState, withProps} from 'recompose';
import {get} from 'lodash';
import renderNothingUnless from '../renderNothingUnless';
import CancelCommentModal from './CancelCommentModal';

/**
 *
 */
export default compose(
	withState('isOpen', 'setOpen', false),
	withProps(({appointment, setOpen}) => ({
		cancelComment:
			(get(appointment, 'cancel_comment'))
			? get(appointment, 'cancel_comment')
			: null,
		onOpen() {
			setOpen(true);
		},
		onClose() {
			setOpen(false);
		}
	})),
	renderNothingUnless(({cancelComment}) =>
		!!cancelComment
	)
)(CancelCommentModal);
