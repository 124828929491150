import {takeLatest} from 'redux-saga';
import {put, call, select, cancelled} from 'redux-saga/effects';
import {uniqueId, sumBy, head} from 'lodash';
import {TYPE_SUCCESS, TYPE_ERROR} from '../api/flash';
import {fetchOne as fetchPlace, fetchSubjects, fetchRelevantPlaces} from '../api/places';
import {fetchInfo} from '../api/info';
import {fetchOne as fetchTopic} from '../api/topics';
import {fetchSubject, fetchAllocations} from '../api/subjects';
import {submit as submitAppointment, checkAvailability} from '../api/appointments';
import {fetchOrganismTopicHeader} from '../api/organisms';
import {pushMessage} from '../actions/flash';
import {
	UPDATE_SUBJECTS,
	FETCH_PLACE,
	FETCH_TOPIC,
	FETCH_RELEVANT_PLACES,
	FETCH_SUBJECTS,
	FETCH_INFO,
	FETCH_ALLOCATIONS,
	CREATE_APPOINTMENT,
	SUBMIT_APPOINTMENT,
	FECTH_ORGANISM_TOPIC_HEADER,
	fetchAllocations as fetchAllocationsAction,
	cancelAppointment,
	updatePlace,
	updateTopic,
	updateSubjects,
	updateInfo,
	updateFirstAvailableSlot,
	updateAllocations,
	updateLoading,
	updatePlaceLoading,
	updateAllocationsLoading,
	updateAppointmentLoading,
	updateAppointmentError,
	updateOrganismTopicHeader
} from '../actions/search';



/**
 *
 */
function* fetchPlaceWorker({payload: {id}}) {
	const place = yield fetchPlace(id);
	yield put(updatePlace(place));
}

/**
 *
 */
function* fetchTopicWorker({payload: {id}}) {
	const topic = yield fetchTopic(id);
	yield put(updateTopic(topic));
}

/**
 *
 */
function* fetchRelevantPlacesWorker({payload: {placeId, topicId}}) {
	yield put(updatePlaceLoading(true));

	try {
		const place = yield fetchRelevantPlaces(placeId, topicId);
		yield put(updatePlace(place));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'places.relevant.fetch.flash.error'
			));
		}
	}

	yield put(updatePlaceLoading(false));
}

/**
 *
 */
function* fetchSubjectsWorker({payload: {placeId, topicId}}) {
	yield put(updateLoading(true));

	try {
		const subjects = yield fetchSubjects(placeId, topicId);
		yield put(updateSubjects(subjects));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'subjects.fetch.flash.error'
			));
		}
	}

	yield put(updateLoading(false));
}


/**
 *
 */
function* fetchInfoWorker({payload: {organismId, placeId, topicId}}) {
	yield put(updateLoading(true));

	try {
		const info = yield fetchInfo(organismId, placeId, topicId);
		yield put(updateInfo(info));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'info.fetch.flash.error'
			));
		}
	}

	yield put(updateLoading(false));
}

/**
 *
 */
function* fetchAllocationsWorker({payload: {subjectId, dateRange, subjectUuid}}) {
	yield put(updateAllocationsLoading(subjectId, true));

	try {
    const allocations = yield fetchAllocations(subjectId, dateRange);
		const availableSlotCount = sumBy(allocations, 'availability_slots.length');

		// si il ne reste plus de créneaux, on met à jour la date du prochain
		// créneau disponible (ref #53598).
		if (!availableSlotCount) {
			const subject = yield fetchSubject(subjectUuid);

			yield put(updateFirstAvailableSlot(
				subjectId,
				subject.firstAvailableSlot
			));
		}

		yield put(updateAllocations(subjectId, dateRange, allocations));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'allocations.fetch.flash.error'
			));
		}
	}

	yield put(updateAllocationsLoading(subjectId, false));
}

/**
 *
 */
function* fetchAllocationsOfSubjectsWorker({payload: {subjects}}) {
    const subjectsWithAllocations = subjects.filter((subject) => subject.allocations && subject.allocations.length);
	const requests = subjectsWithAllocations
		.map(({id, firstAvailableWeek}) =>
        call(fetchAllocations, id, firstAvailableWeek)
    );
	const responses = yield requests;
	const actions = subjectsWithAllocations.map(({id, firstAvailableWeek}, i) =>
		put(updateAllocations(id, firstAvailableWeek, responses[i]))
	);

	yield actions;
}

/**
 *	Checks availability of an appointment before letting the
 *	user book it.
 */
function* createAppointmentWorker({payload: {subjectId, slot}}) {
	yield put(updateAppointmentLoading(true));

	try {
		const error = yield call(checkAvailability, subjectId, slot);

		if (error) {
			yield put(updateAppointmentError(error));
		}
	} catch (e) {
		// osef
	}

	yield put(updateAppointmentLoading(false));
}

/**
 *
 */
function* submitAppointmentWorker({payload: {subjectId, slot, data}}) {
	yield put(updateAppointmentLoading(true));
	const dateRange = yield select(
		({search}) => search.allocations[subjectId].dateRange
	);

	try {
		yield call(submitAppointment, subjectId, slot, data);

		yield put(pushMessage(
			uniqueId(),
			TYPE_SUCCESS,
			'appointment.create.flash.success'
		));
	} catch (e) {
		if (!(yield cancelled())) {
			const {json: {errors}, response: {status}} = e;

			if (status === 400 && errors) {
				yield put(pushMessage(
					uniqueId(),
					TYPE_ERROR,
					head(errors),
					8000
				));
			} else {
				yield put(pushMessage(
					uniqueId(),
					TYPE_ERROR,
					'appointment.create.flash.error'
				));
			}
		}
	} finally {
		yield put(fetchAllocationsAction(subjectId, dateRange));
		yield put(updateAppointmentLoading(false));
		yield put(cancelAppointment());
	}
}

function* fetchOrganismTopicHeaderWorker({payload}) {
	const {organismId, topicId} = payload;
	const organismTopicHeader = yield fetchOrganismTopicHeader(organismId, topicId);
	yield put(updateOrganismTopicHeader(organismTopicHeader));
}



/**
 *
 */
export function* watchFetchPlace() {
	yield* takeLatest(FETCH_PLACE, fetchPlaceWorker);
}

/**
 *
 */
export function* watchFetchTopic() {
	yield* takeLatest(FETCH_TOPIC, fetchTopicWorker);
}

/**
 *
 */
export function* watchFetchRelevantPlaces() {
	yield* takeLatest(FETCH_RELEVANT_PLACES, fetchRelevantPlacesWorker);
}

/**
 *
 */
export function* watchFetchSubjects() {
	yield* takeLatest(FETCH_SUBJECTS, fetchSubjectsWorker);
}

/**
 *
 */
export function* watchFetchInfo() {
	yield* takeLatest(FETCH_INFO, fetchInfoWorker);
}

/**
 *
 */
export function* watchFetchAllocations() {
	yield* takeLatest(FETCH_ALLOCATIONS, fetchAllocationsWorker);
}

/**
 *
 */
export function* watchUpdateSubjects() {
	yield* takeLatest(UPDATE_SUBJECTS, fetchAllocationsOfSubjectsWorker);
}

/**
 *
 */
export function* watchCreateAppointment() {
	yield* takeLatest(CREATE_APPOINTMENT, createAppointmentWorker);
}

/**
 *
 */
export function* watchSubmitAppointment() {
	yield* takeLatest(SUBMIT_APPOINTMENT, submitAppointmentWorker);
}

/**
 *
 */
export function* watchFetchOrganismTopicHeader() {
	yield* takeLatest(FECTH_ORGANISM_TOPIC_HEADER, fetchOrganismTopicHeaderWorker);
}
