import {get, property} from 'lodash';



/**
 *
 */
export const getAll = property('search');

/**
 *
 */
export const getSubjectsWithOpenChat = (state) => {
	const subjects = get(state, 'search.subjects', []);

	return subjects.filter((subject) =>
		!get(subject, 'chat.closed', true)
	);
};
