import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import {ToggleButton} from 'rea11y';
import {chain, isEmpty} from 'lodash';
import classNames from 'classnames';
import FormField from '../FormField';



/**
 *
 */
export default function FiltersForm({
	childPlaces,
	open,
	selectChildPlace,
	onToggle,
	onSubmit
}, {
	intl
}) {
	const handleSubmit = (event) => {
		event.preventDefault();

		onSubmit({
			placeId: chain(childPlaces)
				.map('selected')
				.reject(isEmpty)
				.last()
				.value()
		});
	};

	const className = classNames({
		'search-filters': true,
		'search-filters-open': open
	});

	return (
		<div className={className}>
			<div className="search-filters-toggle">
				<ToggleButton
					onToggle={onToggle}
					pressed={open}
					text={intl.formatMessage({
						id: 'page.search.filters.toggle'
					})}
				/>
			</div>

			<form className="search-filters-form" onSubmit={handleSubmit}>
				<fieldset className="search-filters-places form-fieldset">
					<legend className="form-legend">
						<FormattedMessage id="page.search.filters.place" />
					</legend>

					{childPlaces.map(({places, selected}, level) => (
						<PlacesField
							key={level}
							places={places}
							selected={selected}
							level={level}
							onSelect={selectChildPlace}
						/>
					))}
				</fieldset>

				<button
					className="form-button button button-large button-primary"
					type="submit"
				>
					<FormattedMessage id="page.search.filters.action.submit" />
				</button>
			</form>
		</div>
	);
}

FiltersForm.propTypes = {
	childPlaces: PropTypes.array,
	open: PropTypes.bool,
	selectChildPlace: PropTypes.func,
	onToggle: PropTypes.func,
	onSubmit: PropTypes.func
};

FiltersForm.contextTypes = {
	intl: intlShape
};



/**
 *
 */
function PlacesField({places, selected, level, onSelect}) {
	const handleSelect = (event) =>
		onSelect(level, event.target.value);

	const label = (
		<FormattedMessage
			id="page.search.filters.place.label"
			values={{
				level: level + 1
			}}
		/>
	);

	return (
		<FormField
			key={level}
			name={`child-place-${level}`}
			label={label}
		>
			<PlacesSelect
				places={places}
				selected={selected}
				onChange={handleSelect}
			/>
		</FormField>
	);
}

PlacesField.propTypes = {
	places: PropTypes.array,
	selected: PropTypes.string,
	level: PropTypes.number,
	onSelect: PropTypes.func
};



/**
 *
 */
function PlacesSelect({places, selected, onChange, ...props}, {intl}) {
	const defaultOption = intl.formatMessage({
		id: 'page.search.filters.place.default'
	});

	return (
		<select {...props} value={selected || ''} onChange={onChange}>
			<option value="">{defaultOption}</option>

			{places.map(({uuid, name}) => (
				<option key={uuid} value={uuid}>
					{name}
				</option>
			))}
		</select>
	);
}

PlacesSelect.propTypes = {
	places: PropTypes.array,
	selected: PropTypes.string,
	onChange: PropTypes.func
};

PlacesSelect.contextTypes = {
	intl: intlShape
};
