import {connect} from 'react-redux';
import {find, get} from 'lodash';
import {TYPE_FACE_TO_FACE, TYPE_SKYPE} from '../../api/methods';
import AppointmentForm from './AppointmentForm';
import {compose, withState} from 'recompose';



/**
 *
 */
const mapStateToProps = ({search, user}) => {
	const {subjectId} = search.appointment;
	const subject = find(search.subjects, ['id', subjectId]);
	const isSkypeAppointment = subject.method.types.some((item) => item.type === TYPE_SKYPE);
	const isFaceToFaceAppointment = subject.method.types.some((item) => item.type === TYPE_FACE_TO_FACE);

	return {
		subject,
		user,
		procedure: get(subject, 'procedure_text'),
		needsSkypeAccount: isSkypeAppointment,
		needsAddress: isFaceToFaceAppointment,
		skypeAccount: user.skypeAccount
	};
};


const enhance = compose(
	connect(mapStateToProps),
	withState('selectedType', 'setSelectedType', ''),
);

export default enhance(AppointmentForm);
