import React, {PropTypes} from 'react';
import {injectIntl, intlShape, FormattedMessage} from 'react-intl';
import {Link} from 'react-router';
import {isEmpty} from 'lodash';


const Breadcrumbs = ({routes, intl, lang}) => {
	const breadcrumbs = routes.map((route, i) => {
		// get parents path + current path for each breadcrumb
		const fullPathsList = routes
			.filter((item, j) => j <= i)
			.map((item) => item.path)
			.filter((item) => !isEmpty(item))
			.map((item) => item.replace(':lang', lang));

		return {
			path: fullPathsList.join('/'),
			id: route.id
		};
	}).filter((route) => route.id);

	return (
		<nav className="Breadcrumbs" role="navigation">
			<p className="Breadcrumbs-label">
				<FormattedMessage id="Breadcrumbs.label" />&nbsp;
			</p>

			<ul className="Breadcrumbs-itemList">
				{breadcrumbs.map((item, index) => (
					<li key={`Breadcrumbs-${index}`} className="Breadcrumbs-item">
						<Link to={{pathname: item.path}}>
							<span lang={item.id === 'page.chat.title' ? 'en' : undefined}>
								{intl.formatMessage({id: item.id})}
							</span>
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
};

Breadcrumbs.propTypes = {
	intl: intlShape,
	routes: PropTypes.array,
	lang: PropTypes.string
};

export default injectIntl(Breadcrumbs);
