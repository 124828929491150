import {connect} from 'react-redux';
import {GROUP_MISSED} from '../../api/appointments';
import MissedAppointments from './MissedAppointments';



/**
 *
 */
const mapStateToProps = ({appointments}) => ({
	loading: appointments.loading,
	appointments: appointments.groups[GROUP_MISSED] || []
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MissedAppointments);
