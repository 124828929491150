import {connect} from 'react-redux';
import {initialize, updateProp, validate} from '../actions/forms';
import Form from './Form';



/**
 *
 */
function mapStateToProps(state, {form}) {
	return state.forms[form] || {};
}

/**
 *
 */
function mapDispatchToProps(dispatch, {form}) {
	return {
		initialize(fields) {
			dispatch(initialize(form, fields));
		},
		validate() {
			dispatch(validate(form));
		},
		onChange(field, value) {
			dispatch(updateProp(form, field, 'value', value));
		}
	};
}



/**
 *
 */
const FormContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Form);



export default FormContainer;
