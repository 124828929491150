import {connect} from 'react-redux';
import Search from './Search';
import {hasEstablishmentField} from "../../selectors/home";



/**
 *
 */
const mapStateToProps = (state) => ({
	...state.home,
	withEstablishmentsField: hasEstablishmentField(state),
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
const SearchContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Search);



export default SearchContainer;
