import {connect} from 'react-redux';
import React from 'react';
import {isEmpty} from 'lodash';
import UserMenu from './UserMenu';
import UserNotConnectedMenu from './UserNotConnectedMenu';

const UserMenuItem = ({user}) => {
	const isLoggedIn = !isEmpty(user);
	return (
		<div className="secondary-menu">
			{isLoggedIn
				? <UserMenu firstName={user.firstName} lastName={user.lastName} />
				: <UserNotConnectedMenu />
			}
		</div>
	)
}

/**
 *
 */
const mapStateToProps = function ({user}) {
	return {user};
}

/**
 *
 */
const mapDispatchToProps = () => ({});

/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{
		pure: false
	}
)(UserMenuItem);
