import {sagas as chatSagas} from 'vtech-chat';
import {watchAddDiscussion, watchCreateDiscussion} from './chat';
import {watchResize} from './env';
import {watchValidate} from './forms';
import {watchFetchOrganisms, watchUpdateProp, watchUpdateStatus} from './home';
import {watchFetchSubject} from './subject';
import {
	watchFetchAppointments,
	watchCancelAppointment,
	watchPostponeAppointment
} from './appointments';
import {
	watchFetchPlace,
	watchFetchTopic,
	watchFetchRelevantPlaces,
	watchFetchSubjects,
	watchFetchInfo,
	watchFetchAllocations,
	watchUpdateSubjects,
	watchCreateAppointment,
	watchSubmitAppointment,
	watchFetchOrganismTopicHeader
} from './search';



/**
 *
 */
export default function* sagas() {
	yield [
		...chatSagas,
		watchAddDiscussion(),
		watchCreateDiscussion(),
		watchResize(),
		watchValidate(),
		watchFetchOrganisms(),
		watchUpdateProp(),
		watchUpdateStatus(),
		watchFetchSubject(),
		watchFetchPlace(),
		watchFetchTopic(),
		watchFetchRelevantPlaces(),
		watchFetchSubjects(),
		watchFetchInfo(),
		watchFetchAllocations(),
		watchUpdateSubjects(),
		watchCreateAppointment(),
		watchSubmitAppointment(),
		watchFetchAppointments(),
		watchCancelAppointment(),
		watchPostponeAppointment(),
		watchFetchOrganismTopicHeader()
	];
}
