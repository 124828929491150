import React, {PropTypes} from 'react';
import {intlShape} from 'react-intl';
import AppointmentsMenuContainer from './AppointmentsMenuContainer';



/**
 *
 */
export default function Appointments({children, ...props}, {intl}) {
	const title = intl.formatMessage({
		id: 'page.appointments.title'
	});

	// ugly trick to force rerendering of AppointmentsMenuContainer
	// so the menu links are updated each time the route changes
	const bust = Math.random();

	return (
		<div className="page-appointments">

			<aside className="page-appointments-aside">
				<header className="page-appointments-header">
					<h1 className="page-appointments-title">
						{title}
					</h1>
				</header>

				<AppointmentsMenuContainer bust={bust} />
			</aside>

			<div className="page-appointments-main">
				{children}
			</div>
		</div>
	);
}

Appointments.propTypes = {
	children: PropTypes.node
};

Appointments.contextTypes = {
	intl: intlShape
};
