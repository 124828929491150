import React from 'react';
import Helmet from 'react-helmet';
import {injectIntl, intlShape, FormattedHTMLMessage} from 'react-intl';



/**
 *
 */
const LegalMentions = ({intl}) => {
	const pageTitle = intl.formatMessage({
		id: 'page.legalMentions.title'
	});

	return (
		<article className="Page">
			<Helmet title={pageTitle} />

			<header className="Page-header">
				<h1>{pageTitle}</h1>
			</header>

			<div className="Page-body">
				<FormattedHTMLMessage id="page.legalMentions.body" tagName="div" />
			</div>
		</article>
	);
};

LegalMentions.propTypes = {
	intl: intlShape
};

export default injectIntl(LegalMentions);
