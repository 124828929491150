import {compose, withState, mapProps} from 'recompose';



/**
 *
 */
export default function withToggle(Component, onToggleCallback = () => null) {
	return compose(
		withState('open', 'setOpen', false),
		mapProps(({setOpen, ...props}) => ({
			...props,
			onToggle() {
				setOpen((open) => !open);
				onToggleCallback(props);
			}
		}))
	)(Component);
}
