import {Component, PropTypes, Children, cloneElement} from 'react';



/**
 *
 */
export default class Form extends Component {

	/**
	 *
	 */
	static propTypes = {
		fields: PropTypes.object,
		initialize: PropTypes.func,
		validate: PropTypes.func,
		children: PropTypes.element
	};

	/**
	 *
	 */
	componentDidMount() {
		const {initialize, fields} = this.props;
		initialize(fields);
	}

	/**
	 *
	 */
	handleSubmit(event) {
		event.preventDefault();
		this.props.validate();
	}

	/**
	 *
	 */
	render() {
		const {children, ...props} = this.props;
		const child = Children.only(children);

		return cloneElement(child, props);
	}
}
