import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import Loadable from '../Loadable';
import TableContainer from '../TableContainer';
import MethodTypes from '../MethodTypes';
import AppointmentDate from './AppointmentDate';
import AppointmentTime from './AppointmentTime';
import AppointmentResource from './AppointmentResource';
import AddressModalContainer from './AddressModalContainer';
import ConfirmModal from './ConfirmModal';


/**
 *
 */
export default function ScheduledAppointments({loading, appointments, cancelAppointment}, {intl}) {
	const makeRow = (appointment) => {
		const handleCancel = () =>
			cancelAppointment(appointment.id);

		return [
			<AppointmentDate appointment={appointment} />,
			<AppointmentTime appointment={appointment} />,
			<MethodTypes types={[appointment.method_type]} />,
			appointment.subject.name,
			<AppointmentResource appointment={appointment} />,
			<div className="actions-container">
				<AddressModalContainer appointment={appointment} />
				{appointment.cancelable ? (
					<ConfirmModal onConfirm={handleCancel} action="cancel" />
				) : <button disabled className="button"><FormattedMessage id="appointments.cancel.modal.notCancelable" /></button>}
			</div>
		];
	};

	const tableTitle = intl.formatMessage({
		id: 'page.appointments.scheduled.title'
	});

	return (
		<Loadable loadind={loading}>
			<article className="entry">
				<Helmet title={intl.formatMessage({id: 'page.appointments.scheduled.route.title'})} />

				<header className="entry-header">
					<h2 className="entry-title">
						{tableTitle}
					</h2>
				</header>

				<TableContainer rows={appointments} makeRow={makeRow} caption={tableTitle}>
					<FormattedMessage id="appointments.header.date" />
					<FormattedMessage id="appointments.header.time" />
					<FormattedMessage id="appointments.header.method" />
					<FormattedMessage id="appointments.header.subject" />
					<FormattedMessage id="appointments.header.resource" />
					<FormattedMessage id="appointments.header.actions" />
				</TableContainer>
			</article>
		</Loadable>
	);
}

ScheduledAppointments.propTypes = {
	loading: PropTypes.bool,
	platform: PropTypes.string,
	appointments: PropTypes.array,
	cancelAppointment: PropTypes.func
};

ScheduledAppointments.contextTypes = {
	intl: intlShape
};
