import {property, sortBy} from 'lodash';
import request from './request';



/**
 *
 */
export function fetchAll() {
	return request('/topics')
		.then(property('topics'));
}

/**
 *
 */
export function fetchOne(id) {
	return request(`/topics/${id}`)
		.then(property('topic'));
}


export function fetchEstablishments(organismId, topicId) {
	return request(`/organism/${organismId}/topic/${topicId}/establishments`)
		.then(({establishments=[], student_enrollment=null}) => ({
			establishments: establishments.map(establishment => ({
				...establishment,
				name: establishment.official_name || establishment.name
			})),
			studentEnrollment: student_enrollment
		}));
}

/**
 *
 */
export function hasZones(topic) {
	return topic && topic.sectorize;
}
