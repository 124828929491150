/**
 *
 */
export const INITIALIZE = 'forms/INITIALIZE';
export const UPDATE_STATUS = 'forms/UPDATE_STATUS';
export const UPDATE_PROP = 'forms/UPDATE_PROP';
export const UPDATE_PROPS = 'forms/UPDATE_PROPS';
export const VALIDATE = 'forms/VALIDATE';
export const RESET = 'forms/RESET';



/**
 *
 */
export const initialize = (form, fields) => ({
	type: INITIALIZE,
	payload: {
		form,
		fields
	}
});

/**
 *
 */
export const updateStatus = (form, status) => ({
	type: UPDATE_STATUS,
	payload: {
		form,
		status
	}
});

/**
 *
 */
export const updateProp = (form, field, prop, value) => ({
	type: UPDATE_PROP,
	payload: {
		form,
		field,
		prop,
		value
	}
});

/**
 *
 */
export const updateProps = (form, field, props) => ({
	type: UPDATE_PROPS,
	payload: {
		form,
		field,
		props
	}
});

/**
 *
 */
export const validate = (form) => ({
	type: VALIDATE,
	payload: {
		form
	}
});

/**
 *
 */
export const reset = (form) => ({
	type: RESET,
	payload: {
		form
	}
});
