import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import renderIf from 'render-if';
import Modal from '../Modal';



/**
 *
 */
const AddressModal = ({address, isOpen, onOpen, onClose}) => (
	<div>
		<button className="button button-secondary" onClick={onOpen}>
			<FormattedMessage id="appointments.address.modal.trigger" />
		</button>

		{renderIf(isOpen)(() => (
			<Modal
				title={<FormattedMessage id="appointments.address.modal.title" />}
				onClose={onClose}
			>
				<div className="modal-body">
					<p>{address.address}, {address.city}</p>
				</div>

				<div className="modal-footer">
					<button
						className="button button-large button-secondary"
						onClick={onClose}
					>
						<FormattedMessage id="appointments.address.modal.close" />
					</button>
				</div>
			</Modal>
		))}
	</div>
);

AddressModal.propTypes = {
	address: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onOpen: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

export default AddressModal;
