import React, {PropTypes} from 'react';
import moment from 'moment';



/**
 *
 */
const AppointmentDate = ({appointment}) => (
	<span>{moment(appointment.date).format('DD/MM/YYYY')}</span>
);

AppointmentDate.propTypes = {
	appointment: PropTypes.object
};

export default AppointmentDate;
