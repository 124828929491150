/**
 *
 */
export const SET = 'user/SET';



/**
 *
 */
export const set = (user) => ({
	type: SET,
	payload: {
		user
	}
});
