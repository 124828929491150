/**
 *
 */
export const PUSH_MESSAGE = 'flash/PUSH_MESSAGE';
export const REMOVE_MESSAGE = 'flash/REMOVE_MESSAGE';



/**
 *
 */
export const pushMessage = (id, type, text, time = 5000) => ({
	type: PUSH_MESSAGE,
	payload: {
		id,
		type,
		text,
		time
	}
});

/**
 *
 */
export const removeMessage = (id) => ({
	type: REMOVE_MESSAGE,
	payload: {
		id
	}
});
