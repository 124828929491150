import createReducer from './createReducer';
import {SET_ACTIVE_DISCUSSION} from '../actions/chat';



/**
 *
 */
const initialState = {
	activeDiscussion: undefined
};



/**
 *
 */
const setActiveDiscussion = (state, {id}) => ({
	...state,
	activeDiscussion: id
});



/**
 *
 */
export default createReducer(initialState, {
	[SET_ACTIVE_DISCUSSION]: setActiveDiscussion
});
