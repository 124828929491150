import request from './request';
import {property, groupBy, isEmpty} from 'lodash';
import moment from 'moment';



/**
 *
 */
export const STATUS_UNASSIGNED = 0;
export const STATUS_ASSIGNED = 1;
export const STATUS_DONE = 2;
export const STATUS_CANCELLED = 3;
export const STATUS_MISSED = 4;
export const STATUS_POSTPONED = 5;
export const STATUS_ARCHIVED = 6;

export const GROUP_SCHEDULED = 'GROUP_SCHEDULED';
export const GROUP_ATTENDED = 'GROUP_ATTENDED';
export const GROUP_CANCELLED = 'GROUP_CANCELLED';
export const GROUP_MISSED = 'GROUP_MISSED';
export const GROUP_POSTPONED = 'GROUP_POSTPONED';
export const GROUP_ARCHIVED = 'GROUP_ARCHIVED';



/**
 *
 */
export function fetchByUser(userId) {
	return request(`/appointments/students/${userId}`)
		.then(property('appointments'));
}

/**
 *	This method would return false if the slot is available,
 *	an error message otherwise.
 */
export function checkAvailability(subjectId, slot) {
	const date = slot.dateRange.start.format('YYYY-MM-DD');

	return request(`/appointments/check/${subjectId}/${date}`)
		.then(property('error'))
		.then((error) => (isEmpty(error) ? false : error)); // vomi
}

/**
 *
 */
export function submit(subjectId, slot, data) {
	data.append('subject', subjectId);
	data.append('date', slot.dateRange.start.format('YYYY-MM-DD'));
	data.append('schedule', slot.dateRange.start.format('HH:mm'));

	return request('/appointments/new', data);
}

/**
 *
 */
export function cancel(id) {
	return request('/appointments/cancel', {
		id
	});
}

/**
 *
 */
export function postpone(id) {
	return request('/appointments/report', {
		id
	});
}

/**
 *
 */
export function groupByStatus(appointments) {
	return groupBy(appointments, ({status}) => {
		switch (status) {
			case STATUS_UNASSIGNED:
			case STATUS_ASSIGNED:
				return GROUP_SCHEDULED;

			case STATUS_DONE:
				return GROUP_ATTENDED;

			case STATUS_CANCELLED:
				return GROUP_CANCELLED;

			case STATUS_MISSED:
				return GROUP_MISSED;

			case STATUS_POSTPONED:
				return GROUP_POSTPONED;

			case STATUS_ARCHIVED:
				return GROUP_ARCHIVED;

			default:
				return '';
		}
	});
}

/**
 *
 */
export function findDateRange(appointment) {
	const date = moment(appointment.date);
	const start = moment(appointment.schedule).set({
		year: date.year(),
		month: date.month(),
		date: date.date()
	});

	const duration = appointment.subject.method.time;
	const end = moment(start).add(duration, 'm');

	return {start, end};
}
