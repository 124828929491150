import {connect} from 'react-redux';
import {currentWeekRange} from '../../api/dates';
import {
	fetchAllocations,
	createAppointment,
	cancelAppointment
} from '../../actions/search';
import Allocations from './Allocations';



/**
 *
 */
function mapStateToProps({search}, {subjectId}) {
	if (subjectId in search.allocations) {
		return search.allocations[subjectId];
	}

	return {
		dateRange: currentWeekRange(),
		firstAvailableWeek: currentWeekRange(),
		allocations: [],
		loading: true
	};
}

/**
 *
 */
function mapDispatchToProps(dispatch, {subjectId, subjectUuid}) {
	return {
		fetchAllocations(dateRange) {
			dispatch(fetchAllocations(subjectId, dateRange, subjectUuid));
		},
		createAppointment(date) {
			dispatch(createAppointment(subjectId, date));
		},
		cancelAppointment() {
			dispatch(cancelAppointment());
		}
	};
}



/**
 *
 */
const AllocationsContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(Allocations);



export default AllocationsContainer;
