import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import Helmet from 'react-helmet';
import Loadable from '../Loadable';
import TableContainer from '../TableContainer';
import MethodTypes from '../MethodTypes';
import AppointmentDate from './AppointmentDate';
import AppointmentTime from './AppointmentTime';
import AppointmentResource from './AppointmentResource';
import AddressModalContainer from './AddressModalContainer';
import CancelCommentModalContainer from './CancelCommentModalContainer';
import ReportModal from './ReportModal';



/**
 *
 */
export default function CancelledAppointments({loading, appointments}, {intl}) {
	const makeRow = (appointment) => ([
		<AppointmentDate appointment={appointment} />,
		<AppointmentTime appointment={appointment} />,
		<MethodTypes types={appointment.subject.method.types} />,
		appointment.subject.name,
		<AppointmentResource appointment={appointment} />,
		<div className="actions-container">
			<AddressModalContainer appointment={appointment} />
			<ReportModal appointment={appointment} />
			<CancelCommentModalContainer appointment={appointment} />
		</div>
	]);

	const tableTitle = intl.formatMessage({
		id: 'page.appointments.cancelled.title'
	});

	return (
		<Loadable loadind={loading}>
			<article className="entry">
				<Helmet title={intl.formatMessage({id: 'page.appointments.cancelled.route.title'})} />

				<header className="entry-header">
					<h2 className="entry-title">
						{tableTitle}
					</h2>
				</header>

				<TableContainer rows={appointments} makeRow={makeRow} caption={tableTitle}>
					<FormattedMessage id="appointments.header.date" />
					<FormattedMessage id="appointments.header.time" />
					<FormattedMessage id="appointments.header.method" />
					<FormattedMessage id="appointments.header.subject" />
					<FormattedMessage id="appointments.header.canceller" />
					<FormattedMessage id="appointments.header.actions" />
				</TableContainer>
			</article>
		</Loadable>
	);
}

CancelledAppointments.propTypes = {
	loading: PropTypes.bool,
	appointments: PropTypes.array
};

CancelledAppointments.contextTypes = {
	intl: intlShape
};
