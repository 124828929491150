import React, {PropTypes} from 'react';
import classNames from 'classnames';



/**
 *
 */
export default function Loadable({loading, children}) {
	const className = classNames({
		loadable: true,
		'loadable-loading': loading
	});

	return (
		<div className={className}>
			{children}
		</div>
	);
}

Loadable.propTypes = {
	loading: PropTypes.bool,
	children: PropTypes.node
};
