import React, {PropTypes} from 'react';
import {Modal as Rea11yModal} from 'rea11y';
import {constant} from 'lodash';



/**
 *
 */
export default function Modal({title, onClose, children}) {
	return (
		<Rea11yModal
			labelId="modal-title"
			makeStyle={constant({})}
			onClose={onClose}
		>
			<article className="modal">
				<header className="modal-header">
					<h1 id="modal-title" className="modal-title">
						{title}
					</h1>
				</header>

				{children}
			</article>
		</Rea11yModal>
	);
}

Modal.propTypes = {
	title: PropTypes.node,
	onClose: PropTypes.func,
	children: PropTypes.node
};
