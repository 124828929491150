/**
 *
 */
export default function createReducer(initialState, handlers) {
	return function reducer(state = initialState, {type, payload}) {
		return (type in handlers)
			? handlers[type](state, payload)
			: state;
	};
}
