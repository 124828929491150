import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';



/**
 *
 */
export default function TranslationsDisclaimer() {
	return (
		<p className="TranslationsDisclaimer">
			<FormattedMessage id="TranslationsDisclaimer.text" />
		</p>
	);
}
