import React from 'react';
import {intlShape, injectIntl, FormattedHTMLMessage} from 'react-intl';
import Helmet from 'react-helmet';
import {currentLanguage} from '../../api/locale';

/**
 *
 */
function Accessibility({intl}) {
	const title = intl.formatMessage({
		id: 'page.accessibility.title'
	});

	return (
		<article className="Page">
			<Helmet title={title} />

			<header className="Page-header">
				<h1>{title}</h1>
			</header>

			<div className="Page-body RichText">
				<FormattedHTMLMessage
					id="page.accessibility.body"
					values={{
						baseUrl: `${window.location.origin}${window.rdv.basePath}/${currentLanguage()}`
					}}
					tagName="div"
				/>
			</div>
		</article>
	);
}

Accessibility.propTypes = {
	intl: intlShape
};

export default injectIntl(Accessibility);
