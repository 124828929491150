import {connect} from 'react-redux';
import {fetchSubjects, selectChildPlace} from '../../actions/search';
import SearchAside from './SearchAside';



/**
 *
 */
const mapStateToProps = ({search}) => search;

/**
 *
 */
const mapDispatchToProps = (dispatch, {placeId, topicId}) => ({
	selectChildPlace(level, id) {
		dispatch(selectChildPlace(level, id));
	},
	onSubmit(data) {
		dispatch(fetchSubjects(
			data.placeId || placeId,
			topicId
		));
	}
});



/**
 *
 */
const SearchAsideContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchAside);



export default SearchAsideContainer;
