import {hasZones} from '../api/topics';


const getCurrentField = (field, form) => {
	if (!form) {
		return;
	}
	const currentValue = form.fields[field].value;
	return !!currentValue
		? form.fields[field].options.find(({id}) => String(id) === String(currentValue))
		: null
}

export const getCurrentTopic = (state) => {
	const isFormStateOnly = state.fields !== undefined && state.status !== undefined;
	return getCurrentField('topic', isFormStateOnly ? state : state.forms.home);
}

export const hasEstablishmentField = (state) => hasZones(getCurrentTopic(state));
