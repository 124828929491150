import {get, property} from 'lodash';
import moment from 'moment';
import request from './request';
import {DATE_FORMAT, weekRange} from '../api/dates';



/**
 *
 */
export function findFirstAvailableSlot({allocations = []}) {
	const slot = allocations
		.map((allocation) =>
			get(allocation, ['availability_slots', '0', 'date_time'])
		)
		.filter((date) =>
			!!date
		)
		.sort()
		.shift();

	return slot
		? moment(slot)
		: moment.invalid();
}

/**
 *	Enriches the given subject with information about the
 *	first available time slot.
 */
export function completeSubject(subject) {
	const firstAvailableSlot = findFirstAvailableSlot(subject);
	const firstAvailableWeek = weekRange(firstAvailableSlot);

	return {
		...subject,
		firstAvailableSlot,
		firstAvailableWeek
	};
}

/**
 *
 */
export const fetchSubject = (id) =>
	request(`/subjects/${id}/complete`)
		.then(property('subject'))
		.then(completeSubject);

/**
 *
 */
export function fetchAllocations(subjectId, dateRange) {
	const start = dateRange.start.format(DATE_FORMAT);
	const end = dateRange.end.format(DATE_FORMAT);

	return request(`/subjects/${subjectId}/allocations/${start}/${end}`)
		.then(property('allocations'));
}
