import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';



/**
 *
 */
export default function UserMenu(props) {
	return (
		<div className="user-menu">
			<p className="user-menu-account">
				<FormattedMessage
					id="user.menu.full_name"
					values={props}
				/>
			</p>

			<ul className="user-menu-items">
				<li className="user-menu-item">
					<a className="user-menu-logout" id="user-menu-link" href={window.rdv.logoutUrl}>
						<FormattedMessage id="user.menu.logout" />
					</a>
				</li>

			</ul>
		</div>
	);
}

UserMenu.propTypes = {
	firstName: PropTypes.string.isRequired,
	lastName: PropTypes.string.isRequired
};

