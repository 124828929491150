import React, {PropTypes} from 'react';
import {intlShape} from 'react-intl';
import classNames from 'classnames';
import {TYPE_FACE_TO_FACE, TYPE_PHONE, TYPE_SKYPE} from '../api/methods';



/**
 *
 */
export default function MethodTypes({ className, types }, { intl }) {
	return (
		<div className={classNames('MethodTypes')}>
			{types.map(function (type, index) {
				let icon = MethodTypes.icons[type.type];
				let title = intl.formatMessage({
					id: MethodTypes.labels[type.type],
				});
				return (
					<div key={type.type} className={classNames('MethodType', className)}>
						{!index == 0 && <p>ou</p>}
						<img
							className="MethodType-icon"
							src={`${window.rdv.bundleBasePath}/img/method-${icon}.svg`}
							alt={title}
							title={title}
						/>
					</div>
				);
			})}
		</div>
	);
}
  
MethodTypes.propTypes = {
	className: PropTypes.string,
	types: PropTypes.array.isRequired,
};

MethodTypes.contextTypes = {
	intl: intlShape
};

MethodTypes.icons = {
	[TYPE_FACE_TO_FACE]: 'face-to-face',
	[TYPE_PHONE]: 'phone',
	[TYPE_SKYPE]: 'skype'
};

MethodTypes.labels = {
	[TYPE_FACE_TO_FACE]: 'method.type.face_to_face',
	[TYPE_PHONE]: 'method.type.phone',
	[TYPE_SKYPE]: 'method.type.skype'
};
