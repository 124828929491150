import React, {PropTypes} from 'react';
import renderIf from 'render-if';
import {FormattedMessage} from 'react-intl';

const EstablishmentsFieldset = ({establishment, establishmentSuggestions, onChange}) => {
	const modalClassNamePrefix = "establishment-modal";
	const fieldEstablishmentName = `${modalClassNamePrefix}-form-field-establishment`;
	const fieldEstablishmentErrorId = `${fieldEstablishmentName}-error`;

	return (
		<fieldset className="form-fieldset" aria-required="true">
			<legend className="form-legend">
				<FormattedMessage id="page.home.search.establishment.modal.establishment.label"/>
			</legend>

			<p id={fieldEstablishmentErrorId} className="form-field-error" aria-live="polite">
				{renderIf(establishment.error)(() => (
					<FormattedMessage id={establishment.error}/>
				))}
			</p>

			<ul className="establishment-modal-form-field-establishment-list">
				{establishmentSuggestions.map((suggestion) => {
					const id = `${fieldEstablishmentName}-${suggestion.uuid}`;
					const value = suggestion.uuid;

					return (
						<li key={suggestion.uuid} className={`${fieldEstablishmentName}-list-item`}>
							<label htmlFor={id} id={`${id}-label`} className={`${fieldEstablishmentName}-label`}>
								<span className={`${fieldEstablishmentName}-label-name`}>
									{suggestion.official_name}
								</span>

								<address>
									{renderIf(suggestion.address)(() => (
										<span className={`${fieldEstablishmentName}-label-address`}>{suggestion.address},</span>
									))}

									{renderIf(suggestion.town)(() => (
										<span className={`${fieldEstablishmentName}-label-town`}>
										{suggestion.postal_code} {suggestion.town}.
									</span>
									))}
								</address>
							</label>

							<input type="radio"
										 id={id}
										 className={`${fieldEstablishmentName}-input`}
										 name={fieldEstablishmentName}
										 value={value}
										 onChange={() => onChange(suggestion)}
							/>
						</li>
					);
				})}
			</ul>
		</fieldset>
	);
}

EstablishmentsFieldset.propTypes = {
	establishment: PropTypes.object,
	establishmentSuggestions: PropTypes.array,
	onChange: PropTypes.func,
};

export default EstablishmentsFieldset;
