import {PropTypes} from 'react';



/**
 *
 */
export const userShape = PropTypes.shape({
	id: PropTypes.string
});
