import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import {get} from 'lodash';



/**
 *
 */
export default function AppointmentResource({appointment}) {
	const firstName = get(appointment, 'resource_user.firstname');
	const lastName = get(appointment, 'resource_user.lastname');

	if (firstName || lastName) {
		return (
			<FormattedMessage
				id="appointments.resource"
				values={{
					firstName,
					lastName
				}}
			/>
		);
	}

	return (
		<FormattedMessage
			id="appointments.resource.unspecified"
		/>
	);
}

AppointmentResource.propTypes = {
	appointment: PropTypes.object
};
