import {property} from 'lodash';
import request from './request';
import {completeSubject} from './subjects';



/**
 *
 */
export function fetchAll() {
	return request('/places')
		.then(property('places'));
}

/**
 *
 */
export function fetchOne(id) {
	return request(`/places/${id}`)
		.then(property('place'));
}

/**
 *
 */
export function fetchRelevantPlaces(placeId, topicId) {
	return request(`/places/${placeId}/filter/${topicId}`)
		.then(property('place'));
}

/**
 *
 */
export function fetchSubjects(placeId, topicId) {
	return request(`/places/${placeId}/topics/${topicId}/subjects`)
		.then(property('subjects'))
		.then((subjects) => subjects.map(completeSubject));
}
