import React, {PropTypes} from 'react';
import {intlShape, FormattedMessage} from 'react-intl';
import Modal from '../Modal';
import getLoginUrl from '../../loginUrl';

export default function AppointmentNotConnectedModal({subjectId, cancel}, {intl}) {
	const title = intl.formatMessage({
		id: 'appointment.not_connected.modal.title'
	});

	if (!subjectId) {
		return <noscript />;
	}

	return (
		<Modal title={title} onClose={cancel}>
			<div className="modal-body">
				<p>
					<FormattedMessage id="appointment.not_connected.modal.text" />
				</p>
			</div>

			<div className="modal-footer">
				<button
					className="button button-large button-secondary"
					onClick={cancel}
				>
					<FormattedMessage id="appointment.create.form.action.cancel" />
				</button>

				<a
					className="button button-large button-primary link-button"
					href={getLoginUrl()}
				>
					<FormattedMessage id="appointment.not_connected.modal.link" />
				</a>
			</div>
		</Modal>
	)
}

AppointmentNotConnectedModal.contextTypes = {
	intl: intlShape
};

AppointmentNotConnectedModal.propTypes = {
	subjectId: PropTypes.number,
	cancel: PropTypes.func
};
