import {compose, withState, mapProps} from 'recompose';
import {uniqueId, without} from 'lodash';
import AppointmentFormAttachments from './AppointmentFormAttachments';



/**
 *
 */
export default compose(
	withState('attachments', 'setAttachments', []),
	mapProps(({setAttachments, ...props}) => ({
		...props,
		onAddAttachment() {
			setAttachments((attachments) =>
				attachments.concat(
					uniqueId('attachment-')
				)
			);
		},
		onRemoveAttachment(id) {
			setAttachments((attachments) =>
				without(attachments, id)
			);
		}
	}))
)(AppointmentFormAttachments);
