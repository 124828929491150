import {takeEvery} from 'redux-saga';
import {put} from 'redux-saga/effects';
import {setActiveDiscussion, CREATE_DISCUSSION} from '../actions/chat';
import {socketActions, discussionsActions} from 'vtech-chat';
import {fetchSubject} from '../api/chat';

/**
 *
 */
function* setActiveDiscussionWorker({
	payload: {
		discussion: {
			id
		}
	}
}) {
	yield put(setActiveDiscussion(id));
}

/**
 *
 */
function* createDiscussionWorker({
	payload: {
		chatId,
		user
	}
}) {
	const subject = yield fetchSubject(chatId);

	yield put(
		socketActions.dispatchOnOpening(
			discussionsActions.remoteCreate(
				chatId,
				subject,
				[user]
			)
		)
	);
}

/**
 *
 */
export function* watchAddDiscussion() {
	yield* takeEvery(
		discussionsActions.ADD,
		setActiveDiscussionWorker
	);
}

/**
 *
 */
export function* watchCreateDiscussion() {
	yield* takeEvery(CREATE_DISCUSSION, createDiscussionWorker);
}
