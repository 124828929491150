/**
 *
 */
export const PLATFORM_MOBILE = 'PLATFORM_MOBILE';
export const PLATFORM_DESKTOP = 'PLATFORM_DESKTOP';



/**
 *
 */
export function currentPlatform() {
	return matchMedia('screen and (min-width: 52.5rem)').matches
		? PLATFORM_DESKTOP
		: PLATFORM_MOBILE;
}
