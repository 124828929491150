import update from 'react-addons-update';
import {mapValues} from 'lodash';
import {
	INITIALIZE,
	UPDATE_STATUS,
	UPDATE_PROP,
	UPDATE_PROPS,
	VALIDATE,
	RESET
} from '../actions/forms';
import {STATUS_INITIAL, firstError} from '../api/forms';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	// contains the initial configuration of all forms
	initial: {}
};

const fieldDefaults = {
	value: undefined,
	error: '',
	validators: []
};



/**
 *
 */
const initialize = (state, {form, fields}) => {
	const data = {
		status: STATUS_INITIAL,
		fields: mapValues(fields, (props) => ({
			...fieldDefaults,
			...props
		}))
	};

	return update(state, {
		[form]: {
			$set: data
		},
		initial: {
			[form]: {
				$set: data
			}
		}
	});
};

/**
 *
 */
const updateStatus = (state, {form, status}) =>
	update(state, {
		[form]: {
			status: {
				$set: status
			}
		}
	});

/**
 *
 */
const updateProp = (state, {form, field, prop, value}) =>
	update(state, {
		[form]: {
			fields: {
				[field]: {
					[prop]: {
						$set: value
					}
				}
			}
		}
	});

/**
 *
 */
const updateProps = (state, {form, field, props}) =>
	update(state, {
		[form]: {
			fields: {
				[field]: {
					$merge: props
				}
			}
		}
	});

/**
 *
 */
const validate = (state, {form}) => {
	const fields = mapValues(
		state[form].fields,
		(field) => ({
			...field,
			error: firstError(field, state[form])
		})
	);

	return update(state, {
		[form]: {
			fields: {
				$set: fields
			}
		}
	});
};

/**
 *
 */
const reset = (state, {form}) => ({
	...state,
	[form]: state.initial[form]
});



/**
 *
 */
export default createReducer(initialState, {
	[INITIALIZE]: initialize,
	[UPDATE_STATUS]: updateStatus,
	[UPDATE_PROP]: updateProp,
	[UPDATE_PROPS]: updateProps,
	[VALIDATE]: validate,
	[RESET]: reset
});
