import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import renderIf from 'render-if';
import {findDateRange} from '../../api/appointments';
import MethodTypes from '../MethodTypes';
import {Link} from '../Link';
import {isEmpty} from 'lodash';
import getLoginUrl from '../../loginUrl';
import {userShape} from '../../api/users';

/**
 *
 */
export default function Appointments({appointments, maximum, user}) {
	const firstAppointments = appointments.slice(0, maximum);

	if (isEmpty(user)) {
		return (
			<div className="page-home-appointments">
				<p>
					<FormattedMessage id="page.home.appointments.not_connected.text" />
					<ul className="page-home-appointments-not-connected-items">
						<li>
							<FormattedMessage id="page.home.appointments.not_connected.list.1" />
						</li>
						<li>
							<FormattedMessage id="page.home.appointments.not_connected.list.2" />
						</li>
					</ul>
				</p>

				<div className="page-home-appointments-not-connected-button">
					<a
						className="form-button button button-large button-primary link-button button-largeText"
						href={getLoginUrl()}
					>
						<FormattedMessage id="page.home.appointments.not_connected.link" />
					</a>
				</div>
			</div>
		);
	}

	if (!appointments.length) {
		return <p><FormattedMessage id="page.home.appointments.empty" /></p>;
	}

	return (
		<div className="page-home-appointments">
			<ul className="page-home-appointments-items">
				{firstAppointments.map((appointment, i) => (
					<li key={i} className="page-home-appointments-item">
						<Appointment appointment={appointment} />
					</li>
				))}
			</ul>

			{renderIf(appointments.length > maximum)(
				<Link to={{pathname: '/appointments/scheduled'}}>
					<FormattedMessage id="page.home.appointments.more" />
				</Link>
			)}
		</div>
	);
}

Appointments.propTypes = {
	appointments: PropTypes.array,
	maximum: PropTypes.number,
	user: userShape
};

Appointments.defaultProps = {
	maximum: 3
};



/**
 *
 */
function Appointment({appointment}) {
	const range = findDateRange(appointment);

	return (
		<div className="page-home-appointment">
			<div className="page-home-appointment-method">
				<MethodTypes
					className="MethodType--large"
					types={appointment.subject.method.types}
				/>
			</div>

			<p className="page-home-appointment-date">
				<FormattedMessage
					id="page.home.appointments.date"
					values={{
						start: range.start.toDate(),
						end: range.end.toDate()
					}}
				/>
			</p>

			<p className="page-home-appointment-subject">
				{appointment.subject.name}
			</p>
		</div>
	);
}

Appointment.propTypes = {
	appointment: PropTypes.object
};
