import React from 'react';
import {connect} from 'react-redux';
import renderIf from 'render-if';
import {DiscussionContainer} from 'vtech-chat';
import {get, first, keys, size} from 'lodash';



/**
 *
 */
const mapStateToProps = (state, {chatId}) => {
	const discussions = get(state, ['vtChat', 'discussions', chatId]);
	const id = size(discussions)
		? first(keys(discussions))
		: null;

	return {id};
};

/**
 *
 */
const DiscussionContainerGuard = (props) =>
	renderIf(props.id)(() => (
		<DiscussionContainer {...props} />
	));



export default connect(mapStateToProps)(DiscussionContainerGuard);
