import {size, find, findIndex} from 'lodash';
import update from 'react-addons-update';
import {groupAvailableSlotsByDate} from '../api/allocations';
import {
	CREATE_APPOINTMENT,
	CANCEL_APPOINTMENT,
	UPDATE_PLACE,
	UPDATE_TOPIC,
	UPDATE_SUBJECTS,
	UPDATE_INFO,
	UPDATE_FIRST_AVAILABLE_SLOT,
	UPDATE_ALLOCATIONS,
	UPDATE_LOADING,
	UPDATE_PLACE_LOADING,
	UPDATE_ALLOCATIONS_LOADING,
	UPDATE_APPOINTMENT_LOADING,
	UPDATE_APPOINTMENT_ERROR,
	SELECT_CHILD_PLACE,
	RESET_SUBJECTS,
	UPDATE_ORGANISM_TOPIC_HEADER
} from '../actions/search';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	place: {},
	childPlaces: [],
	topic: {},
	subjects: [],
	info: [],
	allocations: {},
	appointment: {},
	header: null,
	loading: false
};



/**
 *
 */
const createAppointment = (state, {subjectId, slot}) =>
	update(state, {
		appointment: {
			$set: {
				subjectId,
				slot,
				loading: false,
				error: false
			}
		}
	});

/**
 *
 */
const cancelAppointment = (state) =>
	update(state, {
		appointment: {
			$set: {}
		}
	});

/**
 *
 */
const updatePlace = (state, {place}) => {
	const childPlaces = [];

	if (size(place.children)) {
		childPlaces.push({
			places: place.children,
			selected: undefined
		});
	}

	return {
		...state,
		place,
		childPlaces
	};
};

/**
 *
 */
const updateTopic = (state, {topic}) => ({
	...state,
	topic
});

/**
 *
 */
const updateSubjects = (state, {subjects}) => ({
	...state,
	subjects
});

/**
 *
 */
const updateInfo = (state, {info}) => ({
	...state,
	info
});

/**
 *
 */
const updateFirstAvailableSlot = (state, {subjectId, slot}) => {
	const subjectIndex = findIndex(state.subjects, ['id', subjectId]);

	return update(state, {
		subjects: {
			[subjectIndex]: {
				$merge: {
					firstAvailableSlot: slot
				}
			}
		}
	});
};

/**
 *
 */
const updateAllocations =
	(state, {subjectId, dateRange, allocations}) => {
		const subject = find(state.subjects, ['id', subjectId]);
		const slots = groupAvailableSlotsByDate(
			allocations,
			subject.method.time
		);

		return update(state, {
			allocations: {
				[subjectId]: {
					$set: {
						dateRange,
						slots
					}
				}
			}
		});
	};

/**
 *
 */
const updateLoading = (state, {loading}) => ({
	...state,
	loading
});

/**
 *
 */
const updatePlaceLoading = (state, {loading}) => {
	if (size(state.place)) {
		return update(state, {
			place: {
				loading: {
					$set: loading
				}
			}
		});
	}

	return state;
};

/**
 *
 */
const updateAllocationsLoading = (state, {subjectId, loading}) =>
	update(state, {
		allocations: {
			[subjectId]: {
				$apply: (current) => ({
					...current,
					loading
				})
			}
		}
	});

/**
 *
 */
const updateAppointmentLoading = (state, {loading}) => {
	if (size(state.appointment)) {
		return update(state, {
			appointment: {
				loading: {
					$set: loading
				}
			}
		});
	}

	return state;
};

/**
 *
 */
const updateAppointmentError = (state, {error}) => {
	if (size(state.appointment)) {
		return update(state, {
			appointment: {
				error: {
					$set: error
				}
			}
		});
	}

	return state;
};

/**
 *
 */
const selectChildPlace = (state, {level, id}) => {
	const withId = update(state, {
		childPlaces: {
			[level]: {
				selected: {
					$set: id
				}
			}
		}
	});

	const selected = find(
		state.childPlaces[level].places,
		['id', id]
	);

	const spliceArgs = [
		level + 1,
		withId.childPlaces.length
	];

	if (selected && size(selected.children)) {
		spliceArgs.push({
			places: selected.children,
			selected: undefined
		});
	}

	return update(withId, {
		childPlaces: {
			$splice: [
				spliceArgs
			]
		}
	});
};

/**
 *
 */
const resetSubjects = (state) => ({
	...state,
	subjects: initialState.subjects
});


/**
 *
 */
const updateOrganismTopicHeader = (state, {header}) => ({
	...state,
	header
});

/**
 *
 */
export default createReducer(initialState, {
	[CREATE_APPOINTMENT]: createAppointment,
	[CANCEL_APPOINTMENT]: cancelAppointment,
	[UPDATE_PLACE]: updatePlace,
	[UPDATE_TOPIC]: updateTopic,
	[UPDATE_SUBJECTS]: updateSubjects,
	[UPDATE_INFO]: updateInfo,
	[UPDATE_FIRST_AVAILABLE_SLOT]: updateFirstAvailableSlot,
	[UPDATE_ALLOCATIONS]: updateAllocations,
	[UPDATE_LOADING]: updateLoading,
	[UPDATE_PLACE_LOADING]: updatePlaceLoading,
	[UPDATE_ALLOCATIONS_LOADING]: updateAllocationsLoading,
	[UPDATE_APPOINTMENT_LOADING]: updateAppointmentLoading,
	[UPDATE_APPOINTMENT_ERROR]: updateAppointmentError,
	[SELECT_CHILD_PLACE]: selectChildPlace,
	[RESET_SUBJECTS]: resetSubjects,
	[UPDATE_ORGANISM_TOPIC_HEADER]: updateOrganismTopicHeader
});
