import {property, keyBy, mapValues, sortBy} from 'lodash';
import request from './request';



/**
 *
 */
export function fetchAll() {
	return request('/organisms')
		.then(property('organisms'))
		.then(organisms => sortBy(organisms, [({name}) => name.toLocaleLowerCase()]));
}


/**
 *
 */
export function fetchTopics(organismId) {
	return request(`/organisms/${organismId}/topics`)
		.then(property('topics'))
		// jsais pas trop pourquoi le topic est maintenant dans un champ "0" ?
		.then(topics => topics.map(topic => ({
			...topic['0'],
			sectorize: topic.sectorize,
		})))
}


/**
 *
 */
 export function topicsToProps(topics) {
	const labelled = topics.map(topic => ({
		...topic,
		label: topic.name
	}));

	const keyed = keyBy(labelled, 'id');

	return {
		options: labelled,
		descriptions: mapValues(keyed, 'description')
	};
}


/**
 *
 */
export function fetchPlaces(organismId, topicId) {
	return request(`/organism/${organismId}/topic/${topicId}/places`)
		.then(property('places'))
		.then(places => sortBy(places, [({name}) => name.toLocaleLowerCase()]));
}


/**
 *
 */
export function fetchOrganismTopicHeader(organismId, topicId) {
	return request(`/organisms/${organismId}/header/${topicId}`)
		.then(property('header'));
}
