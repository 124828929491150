/**
 *
 */
export const FETCH_ORGANISMS = 'home/FETCH_ORGANISMS';
export const UPDATE_PLACES_LOADING = 'home/UPDATE_PLACES_LOADING';
export const UPDATE_TOPICS_LOADING = 'home/UPDATE_TOPICS_LOADING';

/**
 *
 */
export const fetchOrganisms = () => ({
	type: FETCH_ORGANISMS
});


/**
 *
 */
export const updatePlacesLoading = (loading) => ({
	type: UPDATE_PLACES_LOADING,
	payload: {
		loading
	}
});

/**
 *
 */
export const updateTopicsLoading = (loading) => ({
	type: UPDATE_TOPICS_LOADING,
	payload: {
		loading
	}
});
