import React, {Component, PropTypes} from 'react';
import renderIf from 'render-if';
import {intlShape, FormattedMessage} from 'react-intl';
import EstablishmentModalContainer from './EstablishmentModalContainer';
import EstablishmentsFieldContainer from './EstablishmentsFieldContainer';
import Loadable from '../Loadable';
import SelectField from './SelectField';

export default class SearchForm extends Component {
	constructor(props) {
		super(props);
		this.establishmentModalIsOpen = false;
	}

	render() {
		const {fields, placesLoading, topicsLoading, withEstablishmentsField, onChange} = this.props;
		const intl = this.context.intl;

		const handleFormSubmit = (event) => {
			event.preventDefault();
			this.props.validate();
		};

		// On test les erreurs dans l'ordre d'affichage des inputsm pour savoir lequel doit recevoir le
		// focus en cas d'erreur.
		let fieldToFocus = '';
		if (fields.organism.error) {
			fieldToFocus = 'organism';
		} else if (fields.topic.error) {
			fieldToFocus = 'topic';
		} else if (fields.place.error) {
			fieldToFocus = 'place';
		} else if (fields.establishment.error) {
			fieldToFocus = 'establishment';
		}

		return (
			<form className="page-home-search" onSubmit={handleFormSubmit}>

				<SelectField
					{...fields.organism}
					name="organism"
					onChange={onChange}
					hint={intl.formatMessage({
						id: 'page.home.search.organism.hint'
					})}
					shouldFocus={fieldToFocus === 'organism'}
				/>

				<Loadable loading={topicsLoading}>
					<SelectField
						{...fields.topic}
						name="topic"
						onChange={onChange}
						shouldFocus={fieldToFocus === 'topic'}
					/>
				</Loadable>

				<Loadable loading={placesLoading}>
					{renderIf(!withEstablishmentsField)(() => (
						<SelectField
							{...fields.place}
							name="place"
							onChange={onChange}
							hint={intl.formatMessage({
								id: 'page.home.search.place.hint'
							})}
							shouldFocus={fieldToFocus === 'place'}
						/>
					))}

					{renderIf(withEstablishmentsField)(() => (
						<div>
							<EstablishmentsFieldContainer
								name="establishment"
								{...fields.establishment}
								onValueChange={value => onChange('establishment', value)}
								shouldFocus={fieldToFocus === 'establishment' && !this.establishmentModalIsOpen}
							/>

							<EstablishmentModalContainer
								name="townOrPostalCode"
								fields={fields}
								onToggleState={(isOpen) => {this.establishmentModalIsOpen = isOpen}}
							/>
						</div>
					))}
				</Loadable>

				<button
					className="form-button button button-large button-primary"
					type="submit"
				>
					<FormattedMessage id="page.home.search.submit" />
				</button>
			</form>
		);
	}
}

SearchForm.contextTypes = {
	intl: intlShape
};

SearchForm.propTypes = {
	fields: PropTypes.object,
	topicsLoading: PropTypes.bool,
	placesLoading: PropTypes.bool,
	withEstablishmentsField: PropTypes.bool,
	onChange: PropTypes.func,
	validate: PropTypes.func
};
