import React from 'react';
import {intlShape, injectIntl, FormattedHTMLMessage} from 'react-intl';
import Helmet from 'react-helmet';

/**
 *
 */
function Contact({intl}) {
	const title = intl.formatMessage({
		id: 'page.contact.title'
	});

	return (
		<article className="Page">
			<Helmet title={title} />

			<header className="Page-header">
				<h1>{title}</h1>
			</header>

			<div className="Page-body RichText">
				<FormattedHTMLMessage id="page.contact.body" tagName="div" />
			</div>
		</article>
	);
}

Contact.propTypes = {
	intl: intlShape.isRequired
};

export default injectIntl(Contact);
