import React, {Component, PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import Modal from '../Modal';



/**
 *
 */
export default class ConfirmModal extends Component {

	static propTypes = {
		onConfirm: PropTypes.func,
		action: PropTypes.string
	};

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false
		};
	}

	show() {
		this.setState({
			isOpen: true
		});
	}

	hide() {
		this.setState({
			isOpen: false
		});
	}

	handleConfirm() {
		this.hide();
		this.props.onConfirm();
	}

	render() {
		const {action} = this.props;
		let modal;

		if (this.state.isOpen) {
			const title = (
				<FormattedMessage id={`appointments.${action}.modal.title`} />
			);

			modal = (
				<Modal title={title} onClose={::this.hide}>
					<div className="modal-body">
						<p><FormattedMessage id={`appointments.${action}.modal.text`} /></p>
					</div>

					<div className="modal-footer">
						<button
							className="button button-large button-secondary"
							onClick={::this.hide}
						>
							<FormattedMessage id="appointments.confirm.modal.no" />
						</button>

						<button
							className="button button-large button-primary"
							onClick={::this.handleConfirm}
						>
							<FormattedMessage id="appointments.confirm.modal.yes" />
						</button>
					</div>
				</Modal>
			);
		}

		return (
			<div>
				<button className="button button-secondary" onClick={::this.show}>
					<FormattedMessage id={`appointments.${action}.modal.trigger`} />
				</button>

				{modal}
			</div>
		);
	}
}
