import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape, injectIntl} from 'react-intl';
import {Link} from '../Link';
import {ToggleButton} from 'rea11y';
import classNames from 'classnames';



/**
 *
 */
const ChatButton = ({subjects, isOpen, onToggle, intl}) => {
	const className = classNames({
		'chat-panel': true,
		'chat-panel-open': isOpen
	});

	return (
		<div className={className}>
			<div className="chat-panel-subjects-container">
				<p className="chat-panel-subjects-title">
					<FormattedMessage id="page.search.result.chat.list" />
				</p>

				<ul className="chat-panel-subjects-list">
					{subjects.map((subject, index) =>
						<li key={index}>
							<Link
								className="chat-panel-subject-link"
								to={{
									pathname: `/chats/${subject.chat.chat_id}`
								}}
							>
								{`${subject.name} (${subject.place.name})`}
							</Link>
						</li>
					)}
				</ul>
			</div>

			<div className="chat-panel-button-container">
				<ToggleButton
					onToggle={onToggle}
					pressed={isOpen}
					text={intl.formatMessage({
						id: 'page.search.result.chat'
					})}
				/>
			</div>
		</div>
	);
};

ChatButton.propTypes = {
	subjects: PropTypes.array,
	isOpen: PropTypes.bool,
	onToggle: PropTypes.func,
	intl: intlShape,
	user: PropTypes.object.isRequired
};

ChatButton.contextTypes = {
	intl: intlShape
};

export default injectIntl(ChatButton);
