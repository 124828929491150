import {UPDATE_APPOINTMENTS, UPDATE_APPOINTMENTS_LOADING} from '../actions/appointments';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	groups: {},
	loading: false
};



/**
 *
 */
const updateAppointments = (state, {groups}) => ({
	...state,
	groups
});

/**
 *
 */
const updateAppointmentsLoading = (state, {loading}) => ({
	...state,
	loading
});



/**
 *
 */
export default createReducer(initialState, {
	[UPDATE_APPOINTMENTS]: updateAppointments,
	[UPDATE_APPOINTMENTS_LOADING]: updateAppointmentsLoading
});
