import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import {slotShape} from '../../api/slots';
import Loadable from '../Loadable';
import Modal from '../Modal';
import AppointmentFormContainer from './AppointmentFormContainer';
import {get, groupBy, head} from 'lodash';



/**
 *
 */
export default function AppointmentModal({
	loading,
	error,
	subjectId,
	slot,
	submitAppointment,
	cancelAppointment,
	subjects
}) {
	if (!subjectId) {
		return <noscript />;
	}

	const subject = head(get(
		groupBy(subjects, 'id'),
		subjectId,
		[]
	));

	const title = subject.name;

	if (error) {
		return (
			<Modal title={title} onClose={cancelAppointment}>
				<div className="modal-body">
					<p>{error}</p>
				</div>

				<div className="modal-footer">
					<button
						className="button button-large button-primary"
						onClick={cancelAppointment}
					>
						<FormattedMessage id="appointment.create.modal.close" />
					</button>
				</div>
			</Modal>
		);
	}

	const handleSubmit = (data) =>
		submitAppointment(subjectId, slot, data);

	return (
		<Modal title={title} onClose={cancelAppointment}>
			<Loadable loading={loading}>
				<AppointmentFormContainer
					onSubmit={handleSubmit}
					onCancel={cancelAppointment}
					documents={subject.documents}
					slot={slot}
				/>
			</Loadable>
		</Modal>
	);
}

AppointmentModal.propTypes = {
	loading: PropTypes.bool,
	error: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.string
	]),
	subjectId: PropTypes.number,
	slot: slotShape,
	submitAppointment: PropTypes.func,
	cancelAppointment: PropTypes.func,
	subjects: PropTypes.array
};
