import React, {PropTypes} from 'react';
import {FormattedMessage, FormattedHTMLMessage} from 'react-intl';
import renderIf from 'render-if';
import moment from 'moment';
import classNames from 'classnames';
import {methodShape} from '../../api/methods';
import {dateRangeShape} from '../../api/dates';
import MethodTypes from '../MethodTypes';
import MultilineText from '../MultilineText';
import AllocationsContainer from './AllocationsContainer';
import {TYPE_FACE_TO_FACE, TYPE_PHONE, TYPE_SKYPE} from '../../api/methods';



/**
 *
 */
export default function Result({id, name, open, onToggle, only_phone: onlyPhone, only_email: onlyEmail, phone_number: phoneNumber, email, hasAllocations, ...props}) {
	const className = classNames({
		'search-result': true,
		'search-result-closed': !open,
		'search-result-disabled': !hasAllocations && !onlyPhone && !onlyEmail
	});

	return (
		<article className={className}>
			<ResultTitle>{name}</ResultTitle>

			<div className="search-result-body">
				<div tabIndex={-1} id={`search-result-${id}`} />

				<ResultSummary {...props} />
				<ResultDetail {...props} />
				
				{renderIf(props.method.types.some((item) => item.type === TYPE_FACE_TO_FACE) && open)(() => (
					<ResultAddress {...props} />
				))}

				{renderIf(open)(() => (
					<div>
						<ResultAllocations
							id={id}
							phoneNumber={phoneNumber}
							onlyPhone={onlyPhone}
							email={email}
							onlyEmail={onlyEmail}
							hasAllocations={hasAllocations}
							{...props}
							/>
					</div>
				))}

				{renderIf(onToggle)(() => (
					<button
						className="search-result-detail-toggle"
						onClick={onToggle}
					>
						<FormattedMessage
							id={hasAllocations || onlyPhone || onlyEmail
								? 'page.search.result.toggle'
								: 'page.search.result.no_slots'
							}
							values={{open}}
						/>
					</button>
				))}
			</div>
		</article>
	);
}

Result.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	open: PropTypes.bool,
	method: methodShape,
	onToggle: PropTypes.func,
	only_phone: PropTypes.bool,
	only_email: PropTypes.bool,
	hasAllocations: PropTypes.bool,
	phone_number: PropTypes.string,
	email: PropTypes.string
};



/**
 * this is exported in order to be used by the Info component
 */
export function ResultTitle({children}) {
	return (
		<header className="search-result-header">
			<h2 className="search-result-title">
				{children}
			</h2>
		</header>
	);
}

/**
 *
 */
function ResultSummary({method, firstAvailableSlot}) {
	return (
		<div className="search-result-summary">
			<table>
				<caption>
					<FormattedMessage id="page.search.result.summmary" />
				</caption>

				<thead>
					<tr>
						<th scope="col"><FormattedMessage id="page.search.result.title.type" /></th>
						<th scope="col"><FormattedMessage id="page.search.result.title.duration" /></th>
						<th scope="col"><FormattedMessage id="page.search.result.title.next_appointment" /></th>
					</tr>
				</thead>

				<tbody>
					<tr>
						<td className="search-result-method-type">
							<MethodTypes types={method.types} />
						</td>

						<td className="search-result-method-duration">
							<FormattedMessage
								id="method.duration"
								values={{
									minutes: method.time
								}}
							/>
						</td>

						<td className="search-result-next-appointment">
							{renderIf(firstAvailableSlot.isValid())(() => (
								<p>
									<FormattedMessage
										id="page.search.result.next_appointment"
										values={{
											slot: firstAvailableSlot.toDate()
										}}
									/>
								</p>
							))}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

ResultSummary.propTypes = {
	method: methodShape,
	firstAvailableSlot: PropTypes.instanceOf(moment)
};



/**
 * this is exported in order to be used by the Info component
 */
export function ResultDetail({description}) {
	return !!description && (
		<div className="search-result-detail">
			<p className="search-result-description">
				<MultilineText text={description} />
			</p>
		</div>
	);
}

ResultDetail.propTypes = {
	description: PropTypes.string
};

/**
 *
 */
function ResultAddress({address}) {
	return (
		<div className="search-result-detail">
			<h3 className="search-result-subtitle">
				<FormattedMessage
					id="page.search.result.title.address"
				/>
			</h3>

			<p className="search-result-description">
				{address.address}
				<br />
				{address.city}
			</p>
		</div>
	);
}

ResultAddress.propTypes = {
	address: PropTypes.shape({
		city: PropTypes.string,
		address: PropTypes.string
	}).isRequired
};


/**
 *
 */
function ResultAllocations({id, uuid, onlyPhone, onlyEmail, hasAllocations, phoneNumber, email, firstAvailableWeek, subject_unavailable: subjectUnavailable}) {
	return (
		<div className="search-result-allocations">
			<h4 className="search-result-subtitle">
				{renderIf(hasAllocations && !onlyEmail && !onlyPhone)(() => (
					<FormattedMessage
						id="page.search.result.title.slots"
					/>
				))}
				{renderIf(!hasAllocations && !onlyEmail && !onlyPhone)(() => (
					<FormattedMessage
						id="page.search.result.title.no.slots"
					/>
				))}
				{renderIf(onlyEmail || onlyPhone)(() => (
					<FormattedMessage
						id="page.search.result.title.mailOrPhone"
					/>
				))}
			</h4>

			{renderIf(!hasAllocations && !onlyEmail && !onlyPhone)(() => (
				<p className="search-result-description">
					{subjectUnavailable}
				</p>
			))}

			{renderIf(onlyPhone)(() => (
				<div className="search-result-description">
					<FormattedHTMLMessage
						id="page.search.result.onlyPhone"
						values={{phoneNumber}}
						tagName="div"
					/>
				</div>
			))}

			{renderIf(onlyEmail)(() => (
				<div className="search-result-description">
					<FormattedHTMLMessage
						id="page.search.result.onlyEmail"
						values={{email}}
						tagName="div"
					/>
				</div>
			))}

			{renderIf(!onlyEmail && !onlyPhone && hasAllocations)(() => (
				<AllocationsContainer
					subjectId={id}
					subjectUuid={uuid}
					firstAvailableWeek={firstAvailableWeek}
				/>
			))}
		</div>
	);
}

ResultAllocations.propTypes = {
	id: PropTypes.number,
	firstAvailableWeek: dateRangeShape,
	description: PropTypes.string,
	onlyPhone: PropTypes.bool,
	onlyEmail: PropTypes.bool,
	hasAllocations: PropTypes.bool,
	hasAllocations: PropTypes.bool,
	phoneNumber: PropTypes.string,
	email: PropTypes.string,
	subject_unavailable: PropTypes.string
};
