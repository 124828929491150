import createStore from '../common/createStore';
import reducers from './reducers';
import sagas from './sagas';



/**
 *
 */
export default createStore(reducers, sagas);
