import {connect} from 'react-redux';
import {GROUP_SCHEDULED} from '../../api/appointments';
import Appointments from './Appointments';



/**
 *
 */
const mapStateToProps = ({appointments, user}) => ({
	appointments: appointments.groups[GROUP_SCHEDULED] || [],
	user: user
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Appointments);
