import React from 'react';
import ReactDom from 'react-dom';
import {Provider} from 'react-redux';
import moment from 'moment-timezone';
import store from './store';
import routes from './routes';
import {getLocale} from './api/locale';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/es';



const timeZone = 'Europe/Paris';

//
moment.tz.setDefault(timeZone);
moment.locale(getLocale());

/**
 *
 */
const app = (
	<Provider store={store}>
		{routes}
	</Provider>
);



ReactDom.render(app, document.getElementById('app'));
