import {takeLatest} from 'redux-saga';
import {put, cancelled} from 'redux-saga/effects';
import {uniqueId} from 'lodash';
import {TYPE_ERROR} from '../api/flash';
import {fetchSubject} from '../api/subjects';
import {pushMessage} from '../actions/flash';
import {updateLoading, updateSubjects} from '../actions/search';
import {FETCH_SUBJECT} from '../actions/subject';



/**
 *
 */
export function* fetchSubjectSaga({payload: id}) {
	yield put(updateLoading(true));

	try {
		yield put(updateSubjects([]));
		const subject = yield fetchSubject(id);
		yield put(updateSubjects([subject]));
	} catch (e) {
		if (!(yield cancelled())) {
			yield put(pushMessage(
				uniqueId(),
				TYPE_ERROR,
				'subject.fetch.flash.error'
			));
		}
	}

	yield put(updateLoading(false));
}

/**
 *
 */
export function* watchFetchSubject() {
	yield* takeLatest(FETCH_SUBJECT, fetchSubjectSaga);
}
