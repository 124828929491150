import React, {PropTypes} from 'react';
import {intlShape, FormattedMessage} from 'react-intl';
import renderIf from 'render-if';
import _ from 'lodash';
import Modal from '../Modal';
import Autocomplete from "../Autocomplete";
import FormField from "../FormField";
import {getAutocompleteMatches} from "../../api/forms";
import EstablishmentsFieldsetContainer from "./EstablishmentsFieldsetContainer";

const EstablishmentModal = (
	{fields, onTownOrPostalCodeChange, onHelp, isOpen, onOpen, onClose},
	{intl}
) => {
	const suggestionToKey = (item) => {
		return item.uuid;
	}

	const suggestionToString = (item) => {
		return item ? `${item.town} (${item.postal_code})` : '';
	};

	const canBuildSuggestions = fields.establishment.townOrPostalCode && fields.establishment.data;
	let townOrPostalCodeSuggestions = canBuildSuggestions ? getAutocompleteMatches(fields.establishment.townOrPostalCode, fields.establishment.data, suggestionToString) : [];
	townOrPostalCodeSuggestions = _.uniqBy(townOrPostalCodeSuggestions, suggestionToString);

	const establishmentSuggestions = canBuildSuggestions ? fields.establishment.data.filter((establishmentSuggestion) => {
		return suggestionToString(establishmentSuggestion) === fields.establishment.townOrPostalCode
	}) : [];

	const organismCriteria = fields.organism.options.find(option => Number(option.id) === Number(fields.organism.value));
	const topicCriteria = fields.topic.options.find(option => Number(option.id) === Number(fields.topic.value));

	return (<div>
		<button
			className="page-home-establishment-modal-button button button-large form-button"
			onClick={onOpen}
			type="button"
		>
			<FormattedMessage id="page.home.search.establishment.modal.button"/>
		</button>

		{renderIf(isOpen)(() => (
			<Modal title={<FormattedMessage id="page.home.search.establishment.modal.title"/>} onClose={onClose}>
				<div className="modal-body">
					<FormField
						name="criteria_readonly"
						value={`${organismCriteria.name} / ${topicCriteria.name}`}
						label={<FormattedMessage id="page.home.search.establishment.modal.criteria" />}
						className="page-home-establishment-modal-criteria-readonly-input"
						readOnly
						aria-readonly
					>
						<input type="text"/>
					</FormField>

					<FormField
						name="townOrPostalCode"
						label={<FormattedMessage id="page.home.search.establishment.modal.town_or_postal_code.label"/>}
						hint={intl.formatMessage({id: 'page.home.search.establishment.modal.town_or_postal_code.hint'})}
					>
						<Autocomplete
							name="townOrPostalCode"
							value={fields.establishment.townOrPostalCode ?? ''}
							onValueChange={onTownOrPostalCodeChange}
							suggestions={townOrPostalCodeSuggestions}
							suggestionToKey={suggestionToKey}
							suggestionToString={suggestionToString}
						/>
					</FormField>

					{renderIf(establishmentSuggestions.length)(() => (
							<EstablishmentsFieldsetContainer
								establishment={fields.establishment}
								establishmentSuggestions={establishmentSuggestions}
							/>
					))}
				</div>

				<div className="modal-footer form-button-footer">
					<button type="button" className="button button-large button-secondary" onClick={onClose}>
						<FormattedMessage id="page.home.search.establishment.modal.cancel"/>
					</button>

					<button type="submit" className="button button-large button-secondary" onClick={onHelp}>
						<FormattedMessage id="page.home.search.establishment.modal.help"/>
					</button>

					<button type="submit" className="button button-large button-primary">
						<FormattedMessage id="page.home.search.establishment.modal.submit"/>
					</button>
				</div>
			</Modal>))}
	</div>);
};

EstablishmentModal.propTypes = {
	fields: PropTypes.object.isRequired,
	onTownOrPostalCodeChange: PropTypes.func.isRequired,
	onHelp: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onOpen: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired
};

EstablishmentModal.contextTypes = {
	intl: intlShape
};

export default EstablishmentModal;
