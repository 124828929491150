import withToggle from './withToggle';
import Result from './Result';



/**
 *
 */
export default withToggle(
	Result,
	({id, open}) => {
		if (!open) {
			document.getElementById(`search-result-${id}`).focus();
		}
	}
);
