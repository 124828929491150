import React from 'react';
import {isEmpty, includes, get} from 'lodash';
import {Router, Route, IndexRoute, Redirect} from 'react-router';
import {userActions, socketActions, chatsActions} from 'vtech-chat';
import history from './history';
import store from './store';
import {Languages, DefaultLanguage} from './api/locale';
import {set as setUser} from './actions/user';
import {fetchOrganisms} from './actions/home';
import {fetchAppointments} from './actions/appointments';
import {createDiscussion} from './actions/chat';
import {fetchSubject} from './actions/subject';
import {fetchRelevantPlaces, fetchTopic, fetchSubjects, fetchInfo, fetchOrganismTopicHeader} from './actions/search';
import Intl from './components/Intl';
import Layout from './components/Layout';
import Home from './components/home/Home';
import Search from './components/search/Search';
import Chat from './components/chat/Chat';
import SubjectContainer from './components/subject/SubjectContainer';
import Appointments from './components/appointments/Appointments';
import AppointmentsNotConnected from './components/appointments/AppointmentsNotConnected';
import ScheduledAppointments from './components/appointments/ScheduledAppointmentsContainer';
import AttendedAppointments from './components/appointments/AttendedAppointmentsContainer';
import CancelledAppointments from './components/appointments/CancelledAppointmentsContainer';
import MissedAppointments from './components/appointments/MissedAppointmentsContainer';
import PostponedAppointments from './components/appointments/PostponedAppointmentsContainer';
import ArchivedAppointments from './components/appointments/ArchivedAppointmentsContainer';
import Help from './components/staticFiles/Help';
import Accessibility from './components/staticFiles/Accessibility';
import PersonalData from './components/staticFiles/PersonalData';
import LegalMentions from './components/staticFiles/LegalMentions';
import SiteMap from './components/staticFiles/SiteMap';
import NotFound from './components/staticFiles/NotFound';
import Contact from './components/staticFiles/Contact';



/**
 *
 */
const onEnterRoot = (nextState, replace) => {
	// if there is no language specified in the route,
	// redirects to the same path under the default language.
	if (!includes(Languages, get(nextState, 'params.lang'))) {
		replace(`/${DefaultLanguage}${nextState.location.pathname}`);
	}

	store.dispatch(setUser(window.rdv.user));
}

/**
 *
 */
const onEnterHome = () => {
	const {user} = store.getState();

	store.dispatch(fetchOrganisms());
	if (!isEmpty(user)) {
		store.dispatch(fetchAppointments(user.id));
	}
};

/**
 * place can be empty here, if the user used the "I need help" button from the establishment modal.
 */
const onEnterSearch = ({location}) => {
	const {place, topic, organism} = location.query;

	// For those routes, we absolutely need a place uuid. It would not make sense to call these with
	// an empty place.
	if (place) {
		store.dispatch(fetchRelevantPlaces(place, topic));
		store.dispatch(fetchSubjects(place, topic));
	}

	// But these routes can be called without a place safely.
	store.dispatch(fetchTopic(topic));
	store.dispatch(fetchInfo(organism, place, topic));
	store.dispatch(fetchOrganismTopicHeader(organism, topic));
};

/**
 *
 */
const onEnterChat = ({params}) => {
	const windowUser = window.rdv.user;

	if (isEmpty(windowUser)) {
		return;
	}

	const user = {
		id: windowUser.id,
		firstName: windowUser.firstName,
		lastName: windowUser.lastName,
		head: 0
	};

	store.dispatch(
		userActions.set(user)
	);

	store.dispatch(
		chatsActions.add({
			id: params.id,
			title: 'Chat'
		})
	);

	store.dispatch(
		socketActions.open(
			window.rdv.chat.socket
		)
	);

	store.dispatch(
		createDiscussion(params.id, user)
	);
};

/**
 *
 */
const onEnterSubject = ({params}) => {
	store.dispatch(fetchSubject(params.id));
};



const windowUser = window.rdv.user;

const subRoutes = (
	<Route component={Layout}>
		<IndexRoute component={Home} onEnter={onEnterHome} />
		<Route id="page.search.title" path="search" component={Search} onEnter={onEnterSearch} />
		<Route id="page.chat.title" path="chats/:id" component={Chat} onEnter={onEnterChat} />
		<Route id="page.subject.title" path="subjects/:id" component={SubjectContainer} onEnter={onEnterSubject} />
		{isEmpty(windowUser) === false
			? (
				<Route path="appointments" component={Appointments}>
					<Route
						id="page.appointments.scheduled.route.title"
						path="scheduled"
						component={ScheduledAppointments}
					/>
					<Route
						id="page.appointments.attended.route.title"
						path="attended"
						component={AttendedAppointments}
					/>
					<Route
						id="page.appointments.cancelled.route.title"
						path="cancelled"
						component={CancelledAppointments}
					/>
					<Route
						id="page.appointments.missed.route.title"
						path="missed"
						component={MissedAppointments}
					/>
					<Route
						id="page.appointments.postponed.route.title"
						path="postponed"
						component={PostponedAppointments}
					/>
					<Route
						id="page.appointments.archived.route.title"
						path="archived"
						component={ArchivedAppointments}
					/>
				</Route>
			)
			: (
				<Route id="page.appointments.login" path="login-appointments" component={AppointmentsNotConnected} />
			)
		}
		<Route id="page.help.title" path="help" component={Help} />
		<Route id="page.accessibility.title" path="accessibility" component={Accessibility} />
		<Route id="page.legalMentions.title" path="mentions-legales" component={LegalMentions} />
		<Route id="page.personalData.title" path="personal-data" component={PersonalData} />
		<Route id="page.sitemap.title" path="sitemap" component={SiteMap} user={window.rdv.user} />
		<Route id="page.contact.title" path="contact" component={Contact} />

		<Route path="*">
			<IndexRoute component={NotFound} />
		</Route>
	</Route>
);



/**
 *
 */
const routes = (
	<Router history={history}>
		<Redirect from="/" to={`/${DefaultLanguage}`} />
		<Redirect from="/:lang/appointments" to="/:lang/appointments/scheduled" />
		{isEmpty(windowUser) === false
			? <Redirect from="/:lang/login-appointments" to="/:lang/appointments/scheduled" />
			: <Redirect from="/:lang/appointments/scheduled" to="/:lang/login-appointments" />
		}

		<Route id="page.home.title" path="/:lang" component={Intl} onEnter={onEnterRoot}>
			{subRoutes}
		</Route>
	</Router>
);


export default routes;
