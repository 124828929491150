import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import {dateRangeShape, previousWeekRange, nextWeekRange} from '../../api/dates';



/**
 *
 */
export default function AllocationsPager({dateRange, firstAvailableWeek, onPage}) {
	const hasPrevious = firstAvailableWeek.start
		.isBefore(dateRange.start, 'day');

	let previous;

	if (hasPrevious) {
		previous = (
			<AllocationsPagerLink
				type="previous"
				dateRange={previousWeekRange(dateRange)}
				onPage={onPage}
			/>
		);
	}

	return (
		<div className="pager">
			{previous}

			<AllocationsPagerLink
				type="next"
				dateRange={nextWeekRange(dateRange)}
				onPage={onPage}
			/>
		</div>
	);
}

AllocationsPager.propTypes = {
	dateRange: dateRangeShape,
	firstAvailableWeek: dateRangeShape,
	onPage: PropTypes.func
};



/**
 *
 */
function AllocationsPagerLink({type, dateRange, onPage}) {
	function handleClick() {
		onPage(dateRange);
	}

	return (
		<button
			className={`pager-action pager-action-${type}`}
			onClick={handleClick}
		>
			<span className="pager-title">
				<FormattedMessage id={`page.search.pager.week.${type}`} />
			</span>
			{' '}
			<span className="pager-range">
				<FormattedMessage
					id="page.search.pager.range"
					values={{
						start: dateRange.start.toDate(),
						end: dateRange.end.toDate()
					}}
				/>
			</span>
		</button>
	);
}

AllocationsPagerLink.propTypes = {
	type: PropTypes.string,
	dateRange: dateRangeShape,
	onPage: PropTypes.func
};
