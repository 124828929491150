import {property} from 'lodash';
import request from './request';



/**
 * PlaceId may be undefined here. If that's the case, we have to call the /informationBlocks route
 * without a `place` parameter.
 * Both routes (with or without place), will send back a similar response (information blocks), so
 * it is safe to have only one function for both cases - that allow almost all the rest of the code
 * to ignore the fact that `place` may be empty.
 */
export function fetchInfo(organismId, placeId, topicId) {
	let endpoint = `/topic/${topicId}/organism/${organismId}/informationBlocks`;

	if (placeId) {
		endpoint = `/place/${placeId}${endpoint}`;
	}

	return request(endpoint).then(property('information_blocks'));
}
