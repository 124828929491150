import {SET_PLATFORM} from '../actions/env';
import {currentPlatform} from '../api/env';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	platform: currentPlatform()
};



/**
 *
 */
const setPlatform = (state, {platform}) => ({
	...state,
	platform
});



/**
 *
 */
export default createReducer(initialState, {
	[SET_PLATFORM]: setPlatform
});
