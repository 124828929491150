import React, {PropTypes} from 'react';
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';
import {TYPE_SUCCESS, TYPE_WARNING, TYPE_ERROR} from '../api/flash';



/**
 *
 */
const FlashMessage = ({type, text, onRemove}) => (
	<div className={FlashMessage.classNames[type]} role={FlashMessage.roles[type]}>
		<FormattedHTMLMessage id={text} defaultMessage={text} />

		<button
			type="button"
			className="FlashMessage-closeButton"
			onClick={onRemove}
		>
			<FormattedMessage id="FlashMessage.closeButton" />
		</button>
	</div>
);

FlashMessage.classNames = {
	[TYPE_SUCCESS]: 'FlashMessage FlashMessage--success',
	[TYPE_WARNING]: 'FlashMessage FlashMessage--warning',
	[TYPE_ERROR]: 'FlashMessage FlashMessage--error'
};

FlashMessage.roles = {
	[TYPE_SUCCESS]: 'status',
	[TYPE_WARNING]: 'alert',
	[TYPE_ERROR]: 'alert'
};

FlashMessage.propTypes = {
	type: PropTypes.string,
	text: PropTypes.string,
	onRemove: PropTypes.func
};

export default FlashMessage;
