import React, {PropTypes} from 'react';
import {intlShape} from 'react-intl';
import update from 'react-addons-update';
import {Link as RouterLink, IndexLink as RouterIndexLink, locationShape} from 'react-router';
import {merge} from 'lodash';



/**
 *	Injects the current locale into the URL.
 *	This locale can be overriden in props.
 */
const withLocale = ({locale, ...props}, intl, withQuery, query) =>
	update(props, {
		to: {
			pathname: {
				$set: `/${locale || intl.locale}${props.to.pathname}`
			},
			query: {
				$set: withQuery ? merge({}, query, props.to.query) : props.to.query
			}
		}
	});



/**
 *
 */
export const Link = ({withQuery, ...props}, {intl, location}) => (
	<RouterLink
		activeClassName="active"
		{...withLocale(props, intl, withQuery, location.query)}
	/>
);

Link.propTypes = {
	withQuery: PropTypes.bool
};

Link.defaultProps = {
	withQuery: false
};

Link.contextTypes = {
	intl: intlShape,
	location: locationShape
};

/**
 *
 */
export const IndexLink = (props, {intl}) => (
	<RouterIndexLink
		activeClassName="active"
		{...withLocale(props, intl)}
	/>
);

IndexLink.contextTypes = {
	intl: intlShape
};

/**
 *
 */
export const LocaleLink = ({locale, ...props}, {location}) => {
	const pathname = location.pathname
		.replace(window.rdv.basePath, '')
		.replace(/^\/[a-z]+/i, `/${locale}`);

	return (
		<a
			activeClassName="active"
			href={window.rdv.basePath + pathname + location.search}
			{...props}
		/>
	);
}

LocaleLink.propTypes = {
	locale: PropTypes.string.isRequired
};

LocaleLink.contextTypes = {
	location: locationShape,
};
