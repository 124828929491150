import {mapValues} from 'lodash';
import {PropTypes} from 'react';
import moment from 'moment';



/**
 *
 */
export const DATE_FORMAT = 'YYYY-MM-DD';



/**
 *
 */
export const dateRangeShape = PropTypes.shape({
	start: PropTypes.instanceOf(moment),
	end: PropTypes.instanceOf(moment)
});



/**
 *
 */
export function previousWeek(date) {
	return moment(date).subtract(1, 'week');
}

/**
 *
 */
export function nextWeek(date) {
	return moment(date).add(1, 'week');
}

/**
 *
 */
export function weekRange(date) {
	return {
		start: moment(date).isoWeekday(1),
		end: moment(date).isoWeekday(0).add(1, 'week')
	};
}

/**
 *
 */
export function currentWeekRange() {
	return weekRange(moment());
}

/**
 *
 */
export function previousWeekRange(range) {
	return mapValues(range, previousWeek);
}

/**
 *
 */
export function nextWeekRange(range) {
	return mapValues(range, nextWeek);
}
