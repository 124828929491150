import {connect} from 'react-redux';
import {GROUP_CANCELLED} from '../../api/appointments';
import CancelledAppointments from './CancelledAppointments';



/**
 *
 */
const mapStateToProps = ({appointments}) => ({
	loading: appointments.loading,
	appointments: appointments.groups[GROUP_CANCELLED] || []
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CancelledAppointments);
