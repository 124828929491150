import React from 'react';
import Helmet from 'react-helmet';
import {injectIntl, intlShape, FormattedHTMLMessage} from 'react-intl';



/**
 *
 */
const PersonalData = ({intl}) => {
	const pageTitle = intl.formatMessage({
		id: 'page.personalData.title'
	});

	return (
		<article className="Page">

			<Helmet 
				title={pageTitle} 
				script={[{
					"src": "https://cnous-matomo.cloud-ed.fr/index.php?module=CoreAdminHome&action=optOutJS&divId=matomo-opt-out&language=auto&showIntro=1"
				}]}
			/>

			<header className="Page-header">
				<h1>{pageTitle}</h1>
			</header>

			<div className="Page-body">
				<FormattedHTMLMessage id="page.personalData.body" tagName="div" />
				<div id="matomo-opt-out"></div>
			</div>
		</article>
	);
};

PersonalData.propTypes = {
	intl: intlShape
};

export default injectIntl(PersonalData);
