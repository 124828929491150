import {connect} from 'react-redux';
import Table from './Table';



/**
 *
 */
const mapStateToProps = ({env}) => ({
	platform: env.platform
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Table);
