import React from 'react';
import {FormattedMessage} from 'react-intl';
import {hasEstablishmentField} from '../../selectors/home';
import {hasValue} from '../../validators/form';
import FormContainer from '../FormContainer';
import SearchForm from './SearchForm';


/**
 *
 */
const fields = {
	organism: {
		options: [],
		validators: {
			'page.home.search.organism.error': hasValue
		}
	},
	topic: {
		options: [],
		descriptions: {},
		validators: {
			'page.home.search.topic.error': hasValue
		}
	},
	place: {
		options: [],
		validators: {
			'page.home.search.place.error': (field, formState) => {
				if (hasEstablishmentField(formState)) {
					return true;
				}
				return hasValue(field);
			}
		}
	},
	establishment: {
		value: '',
		uuid: null,
		townOrPostalCode: '',
		placeUuid: null,
		data: [],
		validators: {
			'page.home.search.establishment.error': ({placeUuid}, formState) => {
				if (!hasEstablishmentField(formState)) {
					return true;
				}
				return !!placeUuid;
			}
		}
	}
};


/**
 *
 */
export default function Search(props) {
	return (
		<div>
			<p className="page-home-search-infos">
				<FormattedMessage id="page.home.search.infos"/>
			</p>
			<FormContainer form="home" fields={fields}>
				<SearchForm {...props} />
			</FormContainer>
		</div>
	);
}

