import {connect} from 'react-redux';
import {GROUP_ATTENDED} from '../../api/appointments';
import AttendedAppointments from './AttendedAppointments';



/**
 *
 */
const mapStateToProps = ({appointments}) => ({
	loading: appointments.loading,
	appointments: appointments.groups[GROUP_ATTENDED] || []
});

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AttendedAppointments);
