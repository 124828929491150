import {connect} from 'react-redux';
import {compose, withState, withHandlers} from 'recompose';
import {getSubjectsWithOpenChat} from '../../selectors/search';
import renderNothingUnless from '../renderNothingUnless';
import ChatButton from './ChatButton';
import {getUser} from '../../selectors/user';
import {isEmpty} from 'lodash';


/**
 *
 */
const enhance = compose(
	connect((state) => ({
		subjects: getSubjectsWithOpenChat(state),
		user: getUser(state)
	})),
	withState('isOpen', 'setOpen', false),
	withHandlers({
		onToggle: ({isOpen, setOpen}) => () =>
			setOpen(!isOpen)
	}),
	renderNothingUnless(({subjects, user}) =>
		subjects.length > 0 && !isEmpty(user)
	)
);

export default enhance(ChatButton);
