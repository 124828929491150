import React, {Component, PropTypes} from 'react';
import {intlShape} from 'react-intl';
import {pick, map} from 'lodash';
import {userShape} from '../../api/users';
import {
	GROUP_SCHEDULED,
	GROUP_ATTENDED,
	GROUP_CANCELLED,
	GROUP_MISSED,
	GROUP_POSTPONED,
	GROUP_ARCHIVED
} from '../../api/appointments';
import ActiveLinkContainer from '../ActiveLinkContainer';



/**
 *
 */
export default class AppointmentMenu extends Component {

	static propTypes = {
		user: userShape,
		groups: PropTypes.object,
		fetchAppointments: PropTypes.func
	};

	static contextTypes = {
		intl: intlShape
	};

	componentDidMount() {
		const {user, fetchAppointments} = this.props;
		fetchAppointments(user.id);
	}

	render() {
		// groups ordering
		const groups = pick(this.props.groups, [
			GROUP_SCHEDULED,
			GROUP_ATTENDED,
			GROUP_CANCELLED,
			GROUP_MISSED,
			GROUP_POSTPONED,
			GROUP_ARCHIVED
		]);
		const {intl} = this.context;

		return (
			<ul className="appointments-menu-items">
				{map(groups, (appointments, group) => {
					const url = AppointmentMenu.routes[group];
					const title = intl.formatMessage(
						{
							id: 'page.appointments.menu.group'
						},
						{
							group,
							count: appointments.length
						}
					);

					return (
						<li key={group} className="appointments-menu-item">
							<ActiveLinkContainer
								title={title}
								to={{pathname: `/appointments/${url}`, query: {}}}
							>
								<span>{title}</span>
							</ActiveLinkContainer>
						</li>
					);
				})}
			</ul>
		);
	}
}

AppointmentMenu.routes = {
	[GROUP_SCHEDULED]: 'scheduled',
	[GROUP_ATTENDED]: 'attended',
	[GROUP_CANCELLED]: 'cancelled',
	[GROUP_MISSED]: 'missed',
	[GROUP_POSTPONED]: 'postponed',
	[GROUP_ARCHIVED]: 'archived'
};


