import {takeLatest} from 'redux-saga';
import {put, select} from 'redux-saga/effects';
import {forIn} from 'lodash';
import history from '../history';
import {UPDATE_PROP, UPDATE_STATUS, updateProp, updateProps, reset} from '../actions/forms';
import {FETCH_ORGANISMS, updateTopicsLoading, updatePlacesLoading} from '../actions/home';
import {fetchAll as fetchAllOrganisms, fetchPlaces, fetchTopics, topicsToProps} from '../api/organisms';
import {STATUS_VALID} from '../api/forms';
import {currentLanguage} from '../api/locale';
import {fetchEstablishments, hasZones} from '../api/topics';
import {getCurrentTopic, hasEstablishmentField} from '../selectors/home';
import {PLACE_EMPTY_UUID} from '../constants';

/**
 *
 */
function* fetchOrganismsWorker() {
	yield put(reset('home'));

	const organisms = yield fetchAllOrganisms();
	yield put(updateProp('home', 'organism', 'options', organisms));
}

/**
 *	Updates topics when an organism is selected.
 */
function* fetchTopicsWorker(organismId) {
	yield put(updateTopicsLoading(true));

	const topics = yield fetchTopics(organismId);
	const props = topicsToProps(topics);
	yield put(updateProps('home', 'topic', props));

	yield put(updateTopicsLoading(false));
}

/**
 * Updates places when a topic is selected.
 */
function* fetchPlacesWorker(topicId) {
	yield put(updatePlacesLoading(true));

	const organismId = yield select(
		({forms}) => forms.home.fields.organism.value
	);
	const places = yield fetchPlaces(organismId, topicId);
	yield put(updateProps('home', 'place', {options: places}));

	yield put(updatePlacesLoading(false));
}

/**
 * Updates establishments when a "by zones" topic is selected.
 */
function* fetchEstablishmentsWorker(topicId) {
	yield put(updatePlacesLoading(true));

	const organismId = yield select(
		({forms}) => forms.home.fields.organism.value
	);
	const {establishments, studentEnrollment} = yield fetchEstablishments(organismId, topicId);
	yield put(updateProps('home', 'establishment', {data: establishments}));

	if (studentEnrollment) {
		yield put(updateProps('home', 'establishment', {value: studentEnrollment.name}));
		yield put(updateProps('home', 'establishment', {placeUuid: studentEnrollment.place.uuid}));
	}

	yield put(updatePlacesLoading(false));
}

/**
 *
 */
function* resetLocationFieldsWorker() {
	yield put(updateProps('home', 'establishment', {
		value: '',
		placeUuid: null,
		data: [],
	}));
	yield put(updateProps('home', 'place', {
		value: '',
		options: [],
	}));
}

/**
 *
 */
function* resetTopicField() {
	yield put(updateProps('home', 'topic', {value: ''}));
}

/**
 *
 */
function* updatePropWorker({payload: {form, field, prop, value}}) {
	if (form !== 'home' || prop !== 'value') {
		return;
	}

	// first reset form fields (also when selected organism or topic is the "choose..." select option)
	if ((field === 'organism' || field === 'topic')) {
		yield resetLocationFieldsWorker();
	}
	if (field === 'organism') {
		yield resetTopicField();
	}

	// then get new data
	if (field === 'organism' && value) {
		yield fetchTopicsWorker(value);
	}
	if (field === 'topic' && value) {
		const currentTopic = yield select(getCurrentTopic);
		if (hasZones(currentTopic)) {
			yield fetchEstablishmentsWorker(value);
		} else {
			yield fetchPlacesWorker(value);
		}
	}
}

/**
 * If form has been validated, redirect the user to the `/search` page.
 */
function* submitFormWorker({payload: {form, status}}) {
	if (form !== 'home' || status !== STATUS_VALID) {
		return;
	}

	const query = {};

	/**
	 * in "establishment mode", the user didn't select an actual place but
	 * an establishment tied to a place. So, we don't generate the query the same
	 * way depending on whether we showed a place field or establishment field
	 */
	const establishmentMode = yield select(hasEstablishmentField);
	const {establishment, place, ...fields} = yield select(
		({forms}) => forms[form].fields
	);

	// If establishment.placeUuid equals PLACE_EMPTY_UUID, we simply ignore the place from the form -
	// even though it's supposed to be a required field.
	// This only occurs when the user uses the "I need help" button from the establishment modal.
	if (establishmentMode && establishment.placeUuid !== PLACE_EMPTY_UUID) {
		query.place = establishment.placeUuid;
	} else if (!establishmentMode) {
		fields.place = place;
	}

	forIn(fields, ({options, value}, name) => {
		const matchingObject = options.find(item => String(item.id) === String(value));
		query[name] = matchingObject.uuid;
	});

	history.push({
		pathname: `/${currentLanguage()}/search`,
		query
	});
}


/**
 *
 */
export function* watchFetchOrganisms() {
	yield* takeLatest(FETCH_ORGANISMS, fetchOrganismsWorker);
}


/**
 *
 */
export function* watchUpdateProp() {
	yield* takeLatest(UPDATE_PROP, updatePropWorker);
}

/**
 *
 */
export function* watchUpdateStatus() {
	yield* takeLatest(UPDATE_STATUS, submitFormWorker);
}
