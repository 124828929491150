import React, {Children, PropTypes, cloneElement} from 'react';
import {isEmpty} from 'lodash';


/**
 *	Wraps a form field with a label, and optionally a description
 *	and an error message, linked to the input with aria.
 */
export default function FormField({name, label, hint, description, error, required, children, shouldFocus, className, ...props}) {
	const id = `form-field-${name}`;
	const labelId = `${id}-label`;
	const labels = [];
	const input = Children.only(children);

	const newProps = {
		...props,
		id,
		name,
		className: `form-field-input ${className}`
	};

	let errorComponent;
	let hintComponent;
	let descriptionComponent;

	if (error) {
		const errorId = `${id}-error`;
		errorComponent = (
			<p id={errorId} className="form-field-error">
				{error}
			</p>
		);

		newProps['aria-invalid'] = true;
		newProps['aria-errormessage'] = errorId;
	}

	if (hint) {
		const hintId = `${id}-hint`;
		hintComponent = (
			<p id={hintId} className="form-field-hint">
				{hint}
			</p>
		);

		labels.push(hintId);
	}

	if (description) {
		const descriptionId = `${id}-description`;
		descriptionComponent = (
			<p id={descriptionId} className="form-field-description">
				{description}
			</p>
		);

		newProps['aria-describedby'] = descriptionId;
	}

	const labelProps = {
		id: labelId,
		className: 'form-field-label',
		htmlFor: id
	};

	if (required) {
		labelProps.title = required;

		// If we use the `required` attribute, our form validation will be ignored by the browser, as long as we have
		// missing inputs. We have custom error messages and template that we would like to use in those cases.
		newProps['aria-required'] = true;
	}

	if (!isEmpty(labels)) {
		labels.unshift(labelId);
		newProps['aria-labelledby'] = labels.join(' ');
	}

	newProps.ref = (node) => {
		if	(shouldFocus && node && node.focus) {
			return node.focus();
		}

		return false;
	};

	return (
		<div className="form-field">
			<label {...labelProps}>
				{label}
			</label>

			{errorComponent}
			{hintComponent}
			{cloneElement(input, newProps)}
			{descriptionComponent}
		</div>
	);
}

FormField.propTypes = {
	name: PropTypes.string,
	label: PropTypes.node,
	hint: PropTypes.string,
	description: PropTypes.string,
	error: PropTypes.string,
	required: PropTypes.string,
	children: PropTypes.element,
	shouldFocus: PropTypes.bool
};

FormField.defaultProps = {
	shouldFocus: false
};
