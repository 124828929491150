import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import getLoginUrl from './../loginUrl';

export default function UserNotConnectedMenu() {
	return (
		<div className="user-menu">

			<ul className="user-menu-items">
				<li className="user-menu-item">
					<a className="user-menu-login" id="user-menu-link" href={getLoginUrl()}>
						<FormattedMessage id="user.menu.login" />
					</a>
				</li>

			</ul>
		</div>
	);
}
