import React, {PropTypes} from 'react';
import {intlShape, FormattedMessage} from 'react-intl';
import FormField from '../FormField';
import {getAutocompleteMatches} from "../../api/forms";
import Autocomplete from '../Autocomplete';



const EstablishmentsField = ({
	name,
	value,
	onValueChange,
	onSelectionChange,
	data,
	error,
	shouldFocus,
}, {intl}) => {
	const suggestions = value && data
		? getAutocompleteMatches(value, data, item => item.name)
		: [];

	const label = intl.formatMessage({id: `page.home.search.establishment.label`});

	return (
		<div>
			<p id="form-field-establishment-desc" className="page-home-search-infos">
				<FormattedMessage id="page.home.search.with_establishment"/>
			</p>

			<FormField
				name="establishment"
				label={label}
				required={intl.formatMessage({id: 'form.requiredTitle'}, {label})}
				hint={intl.formatMessage({id: 'page.home.search.establishment.hint'})}
				error={error ? intl.formatMessage({id: error}) : undefined}
				aria-describedby="form-field-establishment-desc"
			>
				<Autocomplete
					{...{name, value, onValueChange, shouldFocus}}
					suggestions={suggestions}
					suggestionToKey={item => item.id}
					suggestionToString={item => item ? item.name : ''}
					onSelectedSuggestionChange={onSelectionChange}
				/>
			</FormField>
		</div>
	)
};

export default EstablishmentsField;

EstablishmentsField.contextTypes = {
	intl: intlShape
};
