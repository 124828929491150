import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import renderIf from 'render-if';
import {size, range} from 'lodash';
import moment from 'moment';
import {DATE_FORMAT, dateRangeShape} from '../../api/dates';
import {slotShape} from '../../api/slots';
import Loadable from '../Loadable';
import AllocationsPager from './AllocationsPager';
import AppointmentModalContainer from './AppointmentModalContainer';



/**
 *
 */
export default function Allocations({
	dateRange,
	firstAvailableWeek,
	slots,
	loading,
	fetchAllocations,
	createAppointment
}) {
	const handlePage = (newDateRange) =>
		fetchAllocations(newDateRange);

	const handleSelect = (slot) =>
		createAppointment(slot);

	return (
		<Loadable loading={loading}>
			<div className="allocations-calendar">
				<AllocationsPager
					dateRange={dateRange}
					firstAvailableWeek={firstAvailableWeek}
					onPage={handlePage}
				/>

				<AllocationsWeek
					dateRange={dateRange}
					slots={slots}
					onSelect={handleSelect}
				/>

				{renderIf(!size(slots))(
					<p className="allocations-calendar-message">
						<FormattedMessage id="page.search.result.no_slots.week" />
					</p>
				)}

				<AppointmentModalContainer />
			</div>
		</Loadable>
	);
}

Allocations.propTypes = {
	dateRange: dateRangeShape,
	firstAvailableWeek: dateRangeShape,
	slots: PropTypes.object,
	loading: PropTypes.bool,
	fetchAllocations: PropTypes.func,
	createAppointment: PropTypes.func
};



/**
 *
 */
function AllocationsWeek({dateRange, slots = {}, onSelect}) {
	return (
		<div className="allocations-calendar-week">
			{range(7).map((offset) => {
				const date = moment(dateRange.start).add(offset, 'day');
				const formatted = date.format(DATE_FORMAT);

				return (
					<AllocationsDay
						key={offset}
						offset={offset}
						date={date}
						slots={
							(formatted in slots)
								? slots[formatted]
								: []
						}
						onSelect={onSelect}
					/>
				);
			})}
		</div>
	);
}

AllocationsWeek.propTypes = {
	dateRange: dateRangeShape,
	slots: PropTypes.object,
	onSelect: PropTypes.func
};



/**
 *
 */
function AllocationsDay({offset, date, slots, onSelect}) {
	return (
		<div className="allocations-calendar-day">
			<h5 className="allocations-calendar-title">
				<span>
					<FormattedMessage
						id={`page.search.result.day.${offset}`}
					/>
				</span>

				<span>
					{date.format('DD MMMM')}
				</span>
			</h5>

			<ul className="allocations-calendar-list">
				{slots.map((slot, i) => (
					<AllocationsSlot
						key={i}
						slot={slot}
						onSelect={onSelect}
					/>
				))}
			</ul>
		</div>
	);
}

AllocationsDay.propTypes = {
	offset: PropTypes.number,
	date: PropTypes.instanceOf(moment),
	slots: PropTypes.array,
	onSelect: PropTypes.func
};



/**
 *
 */
function AllocationsSlot({slot, onSelect}) {
	function handleClick() {
		onSelect(slot);
	}

	return (
		<li>
			<button
				className="allocations-calendar-slot"
				onClick={handleClick}
			>
				{slot.dateRange.start.format('HH:mm')}
			</button>
		</li>
	);
}

AllocationsSlot.propTypes = {
	slot: slotShape,
	onSelect: PropTypes.func
};
