import {PropTypes} from 'react';
import {dateRangeShape} from './dates';



/**
 *
 */
export const slotShape = PropTypes.shape({
	allocationId: PropTypes.number,
	dateRange: dateRangeShape
});
