import {compose, withState, withProps} from 'recompose';
import {get} from 'lodash';
import {TYPE_FACE_TO_FACE} from '../../api/methods';
import renderNothingUnless from '../renderNothingUnless';
import AddressModal from './AddressModal';



/**
 *
 */
export default compose(
	withState('isOpen', 'setOpen', false),
	withProps(({ appointment, setOpen }) => {
		const methodType = [get(appointment, 'method_type', [])];

		const address = methodType.some(item => item.type === TYPE_FACE_TO_FACE)
		  ? get(appointment, 'subject.address')
		  : null;
	  
		return {
		  address,
		  onOpen() {
			setOpen(true);
		  },
		  onClose() {
			setOpen(false);
		  },
		};
	  }),
	renderNothingUnless(({address}) =>
		!!address
	)
)(AddressModal);
