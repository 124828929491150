import {PropTypes} from 'react';



/**
 *
 */
export const TYPE_FACE_TO_FACE = 'Face-to-Face';
export const TYPE_PHONE = 'Phone';
export const TYPE_SKYPE = 'Skype';



/**
 *
 */
export const methodShape = PropTypes.shape({
	type: PropTypes.string,
	time: PropTypes.number
});
