import React, {PropTypes} from 'react';
import {FormattedMessage} from 'react-intl';
import FlashMessage from './FlashMessage';



/**
 *
 */
const Flash = ({messages, onRemoveMessage}) => (
	<div
		className="flash"
		role="alertdialog"
		aria-live="polite"
		aria-labelledby="flash-title"
		aria-describedby="flash-content"
	>
		<p className="screen-reader-only" id="flash-title">
			<FormattedMessage id="flash.message.title" />
		</p>

		<ul id="flash-content">
			{messages.map(({id, ...message}) => (
				<li key={id}>
					<FlashMessage
						{...message}
						onRemove={() => onRemoveMessage(id)}
					/>
				</li>
			))}
		</ul>
	</div>
);

Flash.propTypes = {
	messages: PropTypes.array,
	onRemoveMessage: PropTypes.func
};

export default Flash;
