/**
 *
 */
export const FETCH_PLACE = 'search/FETCH_PLACE';
export const FETCH_TOPIC = 'search/FETCH_TOPIC';
export const FETCH_RELEVANT_PLACES = 'search/FETCH_RELEVANT_PLACES';
export const FETCH_SUBJECTS = 'search/FETCH_SUBJECTS';
export const FETCH_INFO = 'search/FETCH_INFO';
export const FETCH_ALLOCATIONS = 'search/FETCH_ALLOCATIONS';
export const CREATE_APPOINTMENT = 'search/CREATE_APPOINTMENT';
export const SUBMIT_APPOINTMENT = 'search/SUBMIT_APPOINTMENT';
export const CANCEL_APPOINTMENT = 'search/CANCEL_APPOINTMENT';
export const UPDATE_PLACE = 'search/UPDATE_PLACE';
export const UPDATE_TOPIC = 'search/UPDATE_TOPIC';
export const UPDATE_SUBJECTS = 'search/UPDATE_SUBJECTS';
export const UPDATE_INFO = 'search/UPDATE_INFO';
export const UPDATE_FIRST_AVAILABLE_SLOT = 'search/UPDATE_FIRST_AVAILABLE_SLOT';
export const UPDATE_ALLOCATIONS = 'search/UPDATE_ALLOCATIONS';
export const UPDATE_LOADING = 'search/UPDATE_LOADING';
export const UPDATE_PLACE_LOADING = 'search/UPDATE_PLACE_LOADING';
export const UPDATE_ALLOCATIONS_LOADING = 'search/UPDATE_ALLOCATIONS_LOADING';
export const UPDATE_APPOINTMENT_LOADING = 'search/UPDATE_APPOINTMENT_LOADING';
export const UPDATE_APPOINTMENT_ERROR = 'search/UPDATE_APPOINTMENT_ERROR';
export const SELECT_CHILD_PLACE = 'search/SELECT_CHILD_PLACE';
export const RESET_SUBJECTS = 'search/RESET_SUBJECTS';
export const FECTH_ORGANISM_TOPIC_HEADER = 'search/FECTH_ORGANISM_TOPIC_HEADER';
export const UPDATE_ORGANISM_TOPIC_HEADER = 'search/UPDATE_ORGANISM_TOPIC_HEADER';



/**
 *
 */
export const fetchPlace = (id) => ({
	type: FETCH_PLACE,
	payload: {
		id
	}
});

/**
 *
 */
export const fetchTopic = (id) => ({
	type: FETCH_TOPIC,
	payload: {
		id
	}
});

/**
 *
 */
export const fetchRelevantPlaces = (placeId, topicId) => ({
	type: FETCH_RELEVANT_PLACES,
	payload: {
		placeId,
		topicId
	}
});

/**
 *
 */
export const fetchSubjects = (placeId, topicId) => ({
	type: FETCH_SUBJECTS,
	payload: {
		placeId,
		topicId
	}
});

/**
 * placeId may be empty here. If we are not searching for a specific place, we still want to
 * display at least one information block.
 * Organisms that uses sectorization for a topic, are required to give one information block so
 * that we can help students that do not know their establishment.
 */
export const fetchInfo = (organismId, placeId, topicId) => {
	let payload = {organismId, topicId};

	if (placeId) {
		payload = Object.assign(payload, {placeId});
	}

	return {
		type: FETCH_INFO,
		payload
	};
};

/**
 *
 */
export const fetchAllocations = (subjectId, dateRange, subjectUuid) => ({
	type: FETCH_ALLOCATIONS,
	payload: {
		subjectId,
		dateRange,
		subjectUuid
	}
});

/**
 *
 */
export const createAppointment = (subjectId, slot) => ({
	type: CREATE_APPOINTMENT,
	payload: {
		subjectId,
		slot
	}
});

/**
 *
 */
export const submitAppointment = (subjectId, slot, data) => ({
	type: SUBMIT_APPOINTMENT,
	payload: {
		subjectId,
		slot,
		data
	}
});

/**
 *
 */
export const cancelAppointment = () => ({
	type: CANCEL_APPOINTMENT
});

/**
 *
 */
export const updatePlace = (place) => ({
	type: UPDATE_PLACE,
	payload: {
		place
	}
});

/**
 *
 */
export const updateTopic = (topic) => ({
	type: UPDATE_TOPIC,
	payload: {
		topic
	}
});

/**
 *
 */
export const updateSubjects = (subjects) => ({
	type: UPDATE_SUBJECTS,
	payload: {
		subjects
	}
});

/**
 *
 */
export const updateInfo = (info) => ({
	type: UPDATE_INFO,
	payload: {
		info
	}
});

/**
 *
 */
export const updateFirstAvailableSlot = (subjectId, slot) => ({
	type: UPDATE_FIRST_AVAILABLE_SLOT,
	payload: {
		subjectId,
		slot
	}
});

/**
 *
 */
export const updateAllocations =
	(subjectId, dateRange, allocations) => ({
		type: UPDATE_ALLOCATIONS,
		payload: {
			subjectId,
			dateRange,
			allocations
		}
	});

/**
 *
 */
export const updateLoading = (loading) => ({
	type: UPDATE_LOADING,
	payload: {
		loading
	}
});

/**
 *
 */
export const updatePlaceLoading = (loading) => ({
	type: UPDATE_PLACE_LOADING,
	payload: {
		loading
	}
});

/**
 *
 */
export const updateAllocationsLoading =
	(subjectId, loading) => ({
		type: UPDATE_ALLOCATIONS_LOADING,
		payload: {
			subjectId,
			loading
		}
	});

/**
 *
 */
export const updateAppointmentLoading = (loading) => ({
	type: UPDATE_APPOINTMENT_LOADING,
	payload: {
		loading
	}
});

/**
 *
 */
export const updateAppointmentError = (error) => ({
	type: UPDATE_APPOINTMENT_ERROR,
	payload: {
		error
	}
});

/**
 *
 */
export const selectChildPlace = (level, id) => ({
	type: SELECT_CHILD_PLACE,
	payload: {
		level,
		id
	}
});

/**
 *
 */
export const resetSubjects = () => ({
	type: RESET_SUBJECTS
});

/**
 *
 */
export const fetchOrganismTopicHeader = (organismId, topicId) => ({
	type: FECTH_ORGANISM_TOPIC_HEADER,
	payload: {
		organismId,
		topicId
	}
});

/**
 *
 */
export const updateOrganismTopicHeader = (header) => ({
	type: UPDATE_ORGANISM_TOPIC_HEADER,
	payload: {
		header
	}
});
