import {connect} from 'react-redux';
import SearchMain from './SearchMain';



/**
 *
 */
const mapStateToProps = ({search}) => search;

/**
 *
 */
const mapDispatchToProps = () => ({});



/**
 *
 */
const SearchMainContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchMain);



export default SearchMainContainer;
