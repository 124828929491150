import React, {PropTypes} from 'react';
import Helmet from 'react-helmet';
import {isEmpty} from 'lodash';
import Result from '../search/Result';



/**
 *
 */
const Subject = ({subjects, ...props}) => {
	const [subject] = subjects;

	return (
		<div className="Subject">
			<Helmet title={subject.name} />
			<Result {...props} {...subject} hasAllocations={!isEmpty(subject.allocations)} open />
		</div>
	);
};

Subject.propTypes = {
	subjects: PropTypes.array.isRequired
};

export default Subject;
