import update from 'react-addons-update';
import {reject} from 'lodash';
import {PUSH_MESSAGE, REMOVE_MESSAGE} from '../actions/flash';
import createReducer from './createReducer';



/**
 *
 */
const initialState = {
	messages: []
};



/**
 *
 */
const pushMessage = (state, {id, type, text}) =>
	update(state, {
		messages: {
			$apply: (messages) =>
				messages.concat({id, type, text})
		}
	});

/**
 *
 */
const removeMessage = (state, {id}) =>
	update(state, {
		messages: {
			$apply: (messages) => reject(
				messages,
				(message) => (message.id === id)
			)
		}
	});



/**
 *
 */
export default createReducer(initialState, {
	[PUSH_MESSAGE]: pushMessage,
	[REMOVE_MESSAGE]: removeMessage
});
