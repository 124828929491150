/**
 *
 */
export const FETCH_SUBJECT = 'subject/FETCH_SUBJECT';

/**
 *
 */
export const fetchSubject = (id) => ({
	type: FETCH_SUBJECT,
	payload: id
});
