import React, {PropTypes} from 'react';
import {FormattedMessage, intlShape} from 'react-intl';
import FormField from '../FormField';



/**
 *
 */
export default function SelectField({
	name,
	options,
	descriptions,
	value,
	error,
	hint,
	onChange,
	shouldFocus
}, {intl}) {
	const handleChange = (event) => {
		const id = event.target.value;
		onChange(name, id);
	};

	const label = <FormattedMessage id={`page.home.search.${name}.label`} />;
	const labelString = intl.formatMessage({id: `page.home.search.${name}.label`});

	const description = (descriptions && value in descriptions)
		? descriptions[value]
		: undefined;

	const errorMessage = error
		? intl.formatMessage({id: error})
		: undefined;

	const requiredTitle = intl.formatMessage(
		{
			id: 'form.requiredTitle'
		},
		{
			label: labelString
		}
	);

	const defaultOption = intl.formatMessage({
		id: `page.home.search.${name}.default`
	});

	return (
		<FormField
			name={name}
			label={label}
			hint={hint}
			description={description}
			error={errorMessage}
			required={requiredTitle}
			shouldFocus={shouldFocus}
		>
			<select value={value} onChange={handleChange}>
				<option value="">{defaultOption}</option>

				{options.map(({name, id}) => (
					<option key={id} value={id}>
						{name}
					</option>
				))}
			</select>
		</FormField>
	);
}

SelectField.propTypes = {
	name: PropTypes.string,
	hint: PropTypes.string,
	options: PropTypes.array,
	descriptions: PropTypes.object,
	value: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func,
	shouldFocus: PropTypes.bool
};

SelectField.contextTypes = {
	intl: intlShape
};
