import {connect} from 'react-redux';
import {cancelAppointment, postponeAppointment} from '../../actions/appointments';
import {GROUP_POSTPONED} from '../../api/appointments';
import PostponedAppointments from './PostponedAppointments';



/**
 *
 */
const mapStateToProps = ({appointments}) => ({
	loading: appointments.loading,
	appointments: appointments.groups[GROUP_POSTPONED] || []
});

/**
 *
 */
const mapDispatchToProps = (dispatch) => ({
	cancelAppointment(id) {
		dispatch(cancelAppointment(id));
	},
	postponeAppointment(id) {
		dispatch(postponeAppointment(id));
	}
});



/**
 *
 */
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PostponedAppointments);
