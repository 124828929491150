import {findKey, escapeRegExp} from 'lodash';



/**
 *
 */
export const STATUS_INITIAL = 'STATUS_INITIAL';
export const STATUS_INVALID = 'STATUS_INVALID';
export const STATUS_VALID = 'STATUS_VALID';



/**
 *
 */
export function firstError(field, formState) {
	return findKey(
		field.validators,
		(validator) => !validator(field, formState)
	);
}

/**
 *
 */
export function getAutocompleteMatches(input, data, itemStringGetter) {
	const value = input.toLocaleLowerCase();
	const strings = data.map(item => itemStringGetter(item).toLocaleLowerCase());

	let matches = []
	let matchIndexes = [];
	const isAlreadyInResults = (i) => matchIndexes.includes(i);
	const addToResults = (index) => {
		if (!isAlreadyInResults(index)) {
			matches.push(data[index]);
			matchIndexes.push(index);
		}
	}

	// 1. first add exact matches
	strings.forEach((item, i) => {
		if (item === value) {
			addToResults(i);
		}
	});
	// 2. then add stuff beginning by _complete words_ of user input

	const itemBeginsByWordRegex = new RegExp(`^(${escapeRegExp(value)})[ '")-]`, 'g');
	strings.forEach((item, i) => {
		if (itemBeginsByWordRegex.test(item)) {
			addToResults(i);
		}
	});
	// 3. then add stuff _containing_ complete words of user input
	const itemContainsWordRegex = new RegExp(`^.*[ '"(-](${escapeRegExp(value)})[ ")-]`, 'g');
	strings.forEach((item, i) => {
		if (itemContainsWordRegex.test(item)) {
			addToResults(i);
		}
	});
	// 4. then add stuff beginning by user input
	strings.forEach((item, i) => {
		if (item.indexOf(value) === 0) {
			addToResults(i);
		}
	});
	// 5. then add stuff containing user input
	strings.forEach((item, i) => {
		if (item.includes(value)) {
			addToResults(i);
		}
	});

	return matches;
}
